/* eslint-disable no-useless-catch */
import HttpStatusCode from '../../../../common/httpStatusCode';
import * as AbusCloudApi from 'portal-bff-proxy-ts';
import { IPortalConfiguration } from '../../../../common/interfaces';
import { IDeviceComponents, ICylinderDeviceComponent } from '../deviceContracts';
import { BaseRepository } from '../../../../common';
import { mapComfionDeviceDto, mapDeviceAccessDtoToDeviceAccessModel, mapDexitDeviceDto, mapHyenDeviceDto } from '../mapper/device';
import { createApiProxy, IApiProxyOptions } from '../../../../common/createApiProxy';
import { IDeviceModelState } from '../deviceModel';
import { IAppPairingModelState } from '../appPairingModel';
import { convertAppPairingData } from '../convertAppPairingData';
import { IDeviceAccessSearchModel } from '../../../../search/stores/deviceAccessSearchModel';
import { mapDeviceComponentsDto, mapToCylinderComponent } from '../mapper/components';
import { mapHyenLogDtoQueryResult } from '../mapper/deviceLogs';
import { DeviceLogsQueryModel, DeviceLogState } from '../deviceLogsModel';

class DeviceRepository extends BaseRepository {
    private dexitSettingsClient: AbusCloudApi.DexitApi;
    private hyenSettingsClient: AbusCloudApi.HyenApi;
    private hyenLogsClient: AbusCloudApi.HyenApi;
    private comfionSettingsClient: AbusCloudApi.ComfionApi;
    private coreApi: AbusCloudApi.CoreApi;
    private appPairingsClient: AbusCloudApi.HyenApi;

    constructor(configuration: IPortalConfiguration) {
        super(configuration);
        this.dexitSettingsClient = new AbusCloudApi.DexitApi(this.apiConfiguration);
        this.hyenSettingsClient = new AbusCloudApi.HyenApi(this.apiConfiguration);
        this.coreApi = new AbusCloudApi.CoreApi(this.apiConfiguration);
        this.comfionSettingsClient = new AbusCloudApi.ComfionApi(this.apiConfiguration);
        this.hyenLogsClient = new AbusCloudApi.HyenApi(this.apiConfiguration);
        this.appPairingsClient = new AbusCloudApi.HyenApi(this.apiConfiguration);
    }

    public async getDexitDeviceDetails(deviceId: string): Promise<IDeviceModelState | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const proxy = createApiProxy(async () => this.dexitSettingsClient.apiDexitDexitSettingsDeviceIdGet(deviceId), mapDexitDeviceDto, opt);
            const result = await proxy();
            
            // ToDo Extract to own Repository and unify in the device store (see deviceOperatorMetadata)
            const deviceAccessProxy = createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const deviceAccessModel = await deviceAccessProxy();
            result!.deviceAccess = deviceAccessModel;
            return result;
        } catch (e : any) {
            throw e;
        }
    }

    public async getHyenDeviceDetails(deviceId: string): Promise<IDeviceModelState | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const proxy = createApiProxy(async () => this.hyenSettingsClient.apiHyenHyenSettingsDeviceIdGet(deviceId), mapHyenDeviceDto, opt);
            const result = await proxy();
            
            // ToDo Extract to own Repository and unify in the device store (see deviceOperatorMetadata)
            const deviceAccessProxy = createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const deviceAccessModel = await deviceAccessProxy();
            result!.deviceAccess = deviceAccessModel;
            return result;
        } catch (e) {
            throw e;
        }
    }

    public async getComfionDeviceDetails(deviceId: string): Promise<IDeviceModelState | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const proxy = createApiProxy(async () => this.comfionSettingsClient.apiComfionComfionSettingsDeviceIdGet(deviceId), mapComfionDeviceDto, opt);
            const result = await proxy();
            
            const deviceAccessProxy = createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const deviceAccessModel = await deviceAccessProxy();
            result!.deviceAccess = deviceAccessModel;
            return result;
        } catch (e : any) {
            throw e;
        }
    }

    public async getDexitDeviceComponents(deviceId: string, take: number, skip: number): Promise<IDeviceComponents | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const get = this.dexitSettingsClient.apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip);
            const proxy = createApiProxy(async () => get, mapDeviceComponentsDto, opt);
            return proxy();
        } catch (e : any) {
            throw e;
        }
    }

    public async getHyenDeviceLogs(deviceId: string, query: DeviceLogsQueryModel): Promise<{ logs: DeviceLogState[], totalCount: number, availableLogFilterGroupNames: string[] }> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const sortingArrDto: AbusCloudApi.ABUSCloudPortalHyenLogsSortField[] = query.sorting?.map(sort => { return { fieldName: sort.fieldName, direction: sort.direction === 'Ascending' ? AbusCloudApi.ABUSCloudPortalHyenLogsDirection.Ascending : AbusCloudApi.ABUSCloudPortalHyenLogsDirection.Descending } });
            const proxy = createApiProxy(async () => this.hyenLogsClient.apiHyenLogsDeviceIdLogsPost(deviceId, { limit: query.limit, offset: query.offset, sorting: sortingArrDto, groupNames: query.groupNames, fromDate: query.fromDate, toDate: query.toDate }),
                dto => mapHyenLogDtoQueryResult(dto), opt);
            const apiResult = await proxy();
            const result = { logs: apiResult?.items ?? [], totalCount: apiResult?.totalCount ?? 0, availableLogFilterGroupNames: apiResult?.availableLogFilterGroupNames ?? [] };
            return result;
        } catch (e) {
            throw e;
        }
    }

    public async getAppPairings(deviceId: string): Promise<IAppPairingModelState[]> {
        try {
            const proxy = createApiProxy(async () => this.appPairingsClient.apiHyenAppPairingsDeviceIdGet(deviceId), x => x);
            const dtos = await proxy();
            return convertAppPairingData(dtos ?? []);
        } catch (e : any) {
            throw e;
        }
    }

    public async deleteAppPairing(deviceId: string, email: string): Promise<void> {
        try {
            const proxy = createApiProxy(async () => this.appPairingsClient.apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email));
            await proxy();
            return;
        } catch (e : any) {
            throw e;
        }
    }

    public async getDexitCylinders(deviceId: string): Promise<ICylinderDeviceComponent[] | null> {
        try {
            const opt: IApiProxyOptions = { ignoreErrorCodes: [HttpStatusCode.NOT_FOUND] };
            const get = this.dexitSettingsClient.apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId);
            const proxy = createApiProxy(async () => get, (dto) => dto.map(mapToCylinderComponent), opt);
            return proxy();
        } catch (e : any) {
            throw e;
        }
    }

    public async approveAccessRequest(deviceId: string): Promise<IDeviceAccessSearchModel> {
        try {
            await createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdApprovePut(deviceId))();
            const proxy = createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const result = await proxy();
            return result!;
        } catch (e : any) {
            throw e;
        }
    }

    public async extendAccess(deviceId: string, extendUntil: Date): Promise<IDeviceAccessSearchModel> {
        try {
            await createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendUntil.toUTCString()))();
            const proxy = createApiProxy(async () => this.coreApi.apiCoreDeviceAccessDeviceIdGet(deviceId), mapDeviceAccessDtoToDeviceAccessModel);
            const result = await proxy();
            return result!;
        } catch (e : any) {
            throw e;
        }
    }

    public async postDeviceNotes(deviceId: string, notes: string) : Promise<any> {
        try {
            const payload = JSON.stringify(notes);
            const proxy = createApiProxy(async () => this.coreApi.apiCoreResellerNotesDeviceIdPost(deviceId, payload));
            const result = await proxy();
            return result;
        } catch (e : any) {
            throw e;
        }
    }

    public async getDeviceNotes(deviceId: string) : Promise<unknown> {
        try {
            const proxy = createApiProxy(async () => this.coreApi.apiCoreResellerNotesDeviceIdGet(deviceId));
            const result = await proxy();
            return result;
        } catch (e : any) {
            throw e;
        }
    }
}

export default DeviceRepository;
