/* eslint-disable no-useless-catch */
import { observable, toJS, action } from 'mobx';
import DeviceRepository from './repositories/deviceRepository';
import { getPortalConfiguration } from '../../../getPortalConfiguration';
import { getViewStore } from '../../../storeRegistry';

import { DeviceNotificationSettingsModel, IDeviceSettingsState } from './deviceNotificationSettingsModel';
import { DeviceFeatures, DeviceModel, IDeviceModelState } from './deviceModel';
import { createLoggerWithNamespace } from '../../../../logger';
import { IModelWithTimeUpdates } from '../../../common/stores/modelWithTimeUpdates.interface';
import { DeferredDataModel } from '../../../common/stores/deferredDataModel';
import { DeviceModelFactory } from './factory/deviceModelFactory';
import DeviceOperatorMetadataRepository from './repositories/deviceOperatorMetadataRepository';
import { ViewStore } from '../../../common/stores/viewStore';
import { isServer } from '../../../../isServer';
import { DeviceType } from './deviceContracts';
import { DeviceAccessRepository } from './repositories/deviceAccessRepository';
const logger = createLoggerWithNamespace('DeviceStore');

export interface IDeviceStoreState {
    deviceDetails: IDeviceModelState | null;
    deviceId: string | undefined;
    deviceSettings: IDeviceSettingsState | null;
}

export type RefreshOptions = { baseDataRefresh: boolean; components?: boolean; settings?: boolean; notificationSettings?: boolean; appPairings?: boolean; logs?: boolean };

export type LoadOptions = { loadBaseDeviceData: boolean; loadOnly?: boolean } & RefreshOptions;

class DeviceStore implements IModelWithTimeUpdates {
    @observable
    public deviceDetails = new DeferredDataModel<DeviceModel>();

    @observable
    public deviceSettings: DeviceNotificationSettingsModel = new DeviceNotificationSettingsModel(this);

    @observable
    public deviceId?: string;

    public deviceRepository: DeviceRepository;

    public deviceOperatorMetadataRepository: DeviceOperatorMetadataRepository;

    public deviceAccessRepository: DeviceAccessRepository;

    public refreshOptions: RefreshOptions = { baseDataRefresh: false };

    constructor(initialState?: IDeviceStoreState) {
        this.deviceRepository = new DeviceRepository(getPortalConfiguration());
        this.deviceOperatorMetadataRepository = new DeviceOperatorMetadataRepository(getPortalConfiguration());
        this.deviceAccessRepository = new DeviceAccessRepository(getPortalConfiguration());

        if (initialState) {
            if (initialState.deviceDetails) {
                const model = DeviceModelFactory.create(initialState.deviceDetails, { deviceStore: this });
                this.deviceDetails.setInitialData(model);
            }
            this.deviceId = initialState.deviceId;
        }
    }

    public async refreshPeriodically(deviceViewStore: ViewStore) {
        if (!isServer()) {
            if (deviceViewStore) {
                const refreshComponents = (await this.refreshOptions.components) === true && this.deviceDetails.data?.supportsFeature(DeviceFeatures.Components);
                const refreshAppPairings = await this.deviceDetails.data?.supportsFeature(DeviceFeatures.AppPairings);
                const refreshLogs = await this.deviceDetails.data?.supportsFeature(DeviceFeatures.Logs);

                deviceViewStore.removeTimers();

                // DeviceSettings & Operator Metadata
                const timerId = setInterval(async () => {
                    await this.refreshDeviceAccess();
                    await this.refreshDevice(false);
                }, 30000);
                deviceViewStore.addTimer(timerId);

                let requestComponentsInProgress = false;
                if (refreshComponents === true) {
                    const requestComponents = async () => {
                        if (!requestComponentsInProgress) {
                            requestComponentsInProgress = true;
                            await this.refreshDeviceAccess();
                            await this.deviceDetails.data?.refreshComponents();
                            requestComponentsInProgress = false;
                        }
                    };
                    const timerId = setInterval(requestComponents, 15000);
                    deviceViewStore.addTimer(timerId);
                }

                let requestAppPairingsInProgress = false;
                if (refreshAppPairings === true) {
                    const requestAppPairings = async () => {
                        if (!requestAppPairingsInProgress) {
                            requestAppPairingsInProgress = true;
                            await this.refreshDeviceAccess();
                            await this.deviceDetails.data?.getAppPairings(true);
                            requestAppPairingsInProgress = false;
                        }
                    };
                    const timerId = setInterval(requestAppPairings, 15000);
                    deviceViewStore.addTimer(timerId);
                }

                let requestLogsInProgress = false;
                if (refreshLogs === true) {
                    const requestLogs = async () => {
                        if (!requestLogsInProgress) {
                            requestLogsInProgress = true;
                            await this.refreshDeviceAccess();
                            await this.deviceDetails.data?.logs.getLogs(false);
                            requestLogsInProgress = false;
                        }
                    };
                    const timerId = setInterval(requestLogs, 10000);
                    deviceViewStore.addTimer(timerId);
                }
            }
        }
    }

    public async refreshDeviceAccess() {
        if (this.deviceDetails.data?.deviceId) {
            const res = await this.deviceAccessRepository.getDeviceAccess(this.deviceDetails.data?.deviceId);

            if (res) {
                this.deviceDetails.data.updateDeviceAccess(res);
            }
        }
    }

    public timeChanged(): void {
        this.deviceDetails.data?.timeChanged();
    }

    @action
    public async setContext(options: { deviceId: string }) {
        if (this.deviceId !== options.deviceId) {
            this.deviceId = options.deviceId;
            this.deviceDetails.setInitialData(null);
        }
        this.refreshPeriodically(getViewStore());
    }

    @action
    public async setRefreshOptions(options: RefreshOptions) {
        logger.debug('deviceStore.setRefreshOptions', options);
        this.refreshOptions = options;
    }

    @action.bound
    async refreshDevice(withSkeleton = true): Promise<void> {
        if (this.deviceDetails.data) {
            try {
                logger.debug('model state changing to loading');
                if (withSkeleton === true) {
                    this.deviceDetails.setLoading();
                }
                const deviceData = await this.GetDeviceData(this.deviceDetails.data.deviceId, this.deviceDetails.data.type);
                if (deviceData) {
                    this.deviceDetails.data.updateFromJSON(deviceData);
                    this.deviceDetails.setReadyData(this.deviceDetails.data);
                }
            } catch (e: any) {
                this.deviceDetails.setError();
                throw e;
            }
        }
    }

    @action.bound
    async getDevice(deviceId: string, deviceType: DeviceType) {
        this.deviceDetails.setInitialData(null);
        try {
            this.deviceDetails.setLoading();
            const data = await this.GetDeviceData(deviceId, deviceType);
            if (data) {
                this.setDeviceModelLoaded(DeviceModelFactory.create(data, { deviceStore: this }));
            } else {
                this.deviceDetails.setError();
            }
        } catch (e: any) {
            this.deviceDetails.setError();
            throw e;
        }
    }

    @action.bound
    async GetDeviceData(deviceId: string, deviceType: DeviceType): Promise<IDeviceModelState | null> {
        let deviceData = null;
        if (deviceType === DeviceType.Dexit) {
            deviceData = await this.deviceRepository.getDexitDeviceDetails(deviceId);
        } else if (deviceType === DeviceType.Hyen) {
            deviceData = await this.deviceRepository.getHyenDeviceDetails(deviceId);
        } else if (deviceType === DeviceType.Comfion) {
            deviceData = await this.deviceRepository.getComfionDeviceDetails(deviceId);
        }
        const deviceOperatorMetadata = await this.deviceOperatorMetadataRepository.getDeviceMetadata(deviceId);

        if (deviceData) {
            deviceData!.operatorMetadata = deviceOperatorMetadata;
            return deviceData;
        }
        return null;
    }

    @action
    public async get(context: { deviceId: string; deviceType: DeviceType }, loadOptions: LoadOptions) {
        this.setContext(context);
        if (!loadOptions.loadOnly) {
            this.setRefreshOptions(loadOptions);
        }

        await Promise.all([
            loadOptions.loadBaseDeviceData === true || this.deviceDetails.state !== 'ready' ? this.getDevice(context.deviceId, context.deviceType) : null,
            loadOptions.settings === true ? this.deviceSettings.get() : null
        ]);

        await Promise.all([
            loadOptions.notificationSettings === true ? this.deviceDetails.data?.systemServiceConfigurations!.get() : null,
            loadOptions.components === true && this.deviceDetails.data?.supportsFeature(DeviceFeatures.Components) ? this.deviceDetails.data?.getDeviceComponents(true) : null,
            loadOptions.appPairings === true && this.deviceDetails.data?.supportsFeature(DeviceFeatures.AppPairings) ? this.deviceDetails.data?.getAppPairings(true) : null,
            loadOptions.logs === true && this.deviceDetails.data?.supportsFeature(DeviceFeatures.Logs) ? this.deviceDetails.data?.logs.getLogs(true) : null
        ]);
    }

    private setDeviceModelLoaded(deviceModel: DeviceModel) {
        this.deviceDetails.setReadyData(deviceModel);
    }

    @action.bound
    public async postDeviceNotes(deviceId: string, notes: string): Promise<void> {
        try {
            await this.deviceRepository.postDeviceNotes(deviceId, notes);
        } catch (e) {
            logger.error('Failed to post device notes', e);
            throw e;
        }
    }

@action.bound
    public async getDeviceNotes(deviceId: string): Promise<unknown> {
        try {
            const notes = await this.deviceRepository.getDeviceNotes(deviceId);
            return notes;
        } catch (e) {
            logger.error('Failed to retrieve device notes', e);
            throw e;
        }
    }

public toJSON(): IDeviceStoreState {
    return toJS<IDeviceStoreState>({
        deviceDetails: this.deviceDetails.data?.toJSON() ?? null,
        deviceId: this.deviceId,
        deviceSettings: this.deviceSettings.toJSON()
    });
}
}

export default DeviceStore;
