import * as React from 'react';
import './skeleton.scss';

export interface ISkeletonProps {
    className?: string;
    circle?: boolean;
    style?: React.CSSProperties;
}

const defaults = (): ISkeletonProps => {
    return {
        circle: false,
        className: ''
    };
};

export const Skeleton: React.FunctionComponent<ISkeletonProps> = (props) => {
    props = { ...defaults(), ...props };
    const classes = ['skeleton'];

    if (props.circle) {
        classes.push('circle');
    }

    if (props.className) {
        classes.push(props.className);
    }

    return <span className={classes.join(' ')} style={props.style} />;
};
