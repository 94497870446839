import React from 'react';
import { useTranslation } from 'react-i18next';
import { KnownRoutes } from '../../routing/knownroutes';
import './prelogin.scss';
import { TRANSLATIONS_NAMESPACE } from '../../../access';
import marketingImageDE from '../../../assets/backgrounds/prelogin.marketing.de.svg';
import marketingImageEN from '../../../assets/backgrounds/prelogin.marketing.en.svg';

const PreLogin: React.FC = () => {
    const { t, i18n } = useTranslation(TRANSLATIONS_NAMESPACE);

    const navigateToRegister = (acrValue: string) => {
        window.location.href = `${KnownRoutes.Register}?acr_values=${acrValue}`;
    };

    const navigateToLogin = () => {
        window.location.href = KnownRoutes.Login;
    };

    // Determine the current language
    const currentLanguage = i18n.language; // e.g., 'de-DE' or 'de'
    
    // Extract the base language, e.g., 'de' from 'de-DE'
    const normalizedLanguage = currentLanguage.split('-')[0];

    const marketingImage = normalizedLanguage === 'de'
        ? marketingImageDE
        : marketingImageEN;

    return (
        <div className='container-fluid preLogin'>
            <div className='row section signIn'>
                <div className='col-3 offset-3'>
                    <h6>{t('PreLogin.SignIn.PreHeader')}</h6>
                    <h4>{t('PreLogin.SignIn.Header')}</h4>
                    <p className='m-0 typo-c1'>{t('PreLogin.SignIn.Description')}</p>
                </div>
                <div className='col-3 d-flex flex-column'>
                    <button onClick={navigateToLogin} className='mt-auto btn preLogin-button'>
                        {t('PreLogin.SignIn.Button')}
                    </button>
                </div>
            </div>
            <div className='row'>
                <div className='col-6 section installer'>
                    <h6>{t('PreLogin.Register.Installer.PreHeader')}</h6>
                    <h4>{t('PreLogin.Register.Installer.Header')}</h4>
                    <p className='m-0 typo-c1'>{t('PreLogin.Register.Installer.Description')}</p>
                    <button onClick={() => navigateToRegister('com:abus:preferred_customer_type:b2b')} className='btn preLogin-button-outline'>
                        {t('PreLogin.Register.Installer.Button')}
                    </button>
                </div>
                <div className='col-6 section user'>
                    <h6>{t('PreLogin.Register.User.PreHeader')}</h6>
                    <h4>{t('PreLogin.Register.User.Header')}</h4>
                    <p className='m-0 typo-c1'>{t('PreLogin.Register.User.Description')}</p>
                    <button onClick={() => navigateToRegister('com:abus:preferred_customer_type:b2c')} className='btn preLogin-button-outline'>
                        {t('PreLogin.Register.User.Button')}
                    </button>
                </div>
            </div>
            <div className='row'>
                <div className='col-6 section marketing'>
                    <h6>{t('PreLogin.Marketing.PreHeader')}</h6>
                    <h4>{t('PreLogin.Marketing.Header')}</h4>
                    <p className='m-0 typo-c1'>{t('PreLogin.Marketing.Description')}</p>
                </div>
                <div className='col-6 section marketing marketingImage'>
                    <div className='marketingImageContainer'>
                        <object data={marketingImage} type='image/svg+xml' className='marketingImageObject'></object>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreLogin;
