"use strict";
// tslint:disable
/**
 * ABUS Cloud API
 * Backend for Frontend - ABUS Cloud Portal
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TectiqApi = exports.TectiqApiFactory = exports.TectiqApiFp = exports.TectiqApiAxiosParamCreator = exports.HyenApi = exports.HyenApiFactory = exports.HyenApiFp = exports.HyenApiAxiosParamCreator = exports.DexitApi = exports.DexitApiFactory = exports.DexitApiFp = exports.DexitApiAxiosParamCreator = exports.CoreApi = exports.CoreApiFactory = exports.CoreApiFp = exports.CoreApiAxiosParamCreator = exports.ComfionApi = exports.ComfionApiFactory = exports.ComfionApiFp = exports.ComfionApiAxiosParamCreator = exports.ABUSCloudPortalTectiqSettingsConnectionState = exports.ABUSCloudPortalHyenMessagesDeviceConnectionState = exports.ABUSCloudPortalHyenLogsDirection = exports.ABUSCloudPortalDexitSettingsSignalState = exports.ABUSCloudPortalDexitSettingsCylinderComponentTypes = exports.ABUSCloudPortalDexitSettingsBatteryState = exports.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus = exports.ABUSCloudPortalDexitMessagesDexitControlDeviceType = exports.ABUSCloudPortalDexitMessagesDexitConnectionState = exports.ABUSCloudPortalDexitBatteryReportReportFrequency = exports.ABUSCloudPortalCommonFeaturesSearchSearchResultType = exports.ABUSCloudPortalCommonFeaturesFirmwareReleaseState = exports.ABUSCloudPortalCommonFeaturesAccessDeviceAccessState = void 0;
const globalImportUrl = require("url");
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalCommonFeaturesAccessDeviceAccessState;
(function (ABUSCloudPortalCommonFeaturesAccessDeviceAccessState) {
    ABUSCloudPortalCommonFeaturesAccessDeviceAccessState["None"] = "None";
    ABUSCloudPortalCommonFeaturesAccessDeviceAccessState["Requested"] = "Requested";
    ABUSCloudPortalCommonFeaturesAccessDeviceAccessState["Approved"] = "Approved";
})(ABUSCloudPortalCommonFeaturesAccessDeviceAccessState = exports.ABUSCloudPortalCommonFeaturesAccessDeviceAccessState || (exports.ABUSCloudPortalCommonFeaturesAccessDeviceAccessState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalCommonFeaturesFirmwareReleaseState;
(function (ABUSCloudPortalCommonFeaturesFirmwareReleaseState) {
    ABUSCloudPortalCommonFeaturesFirmwareReleaseState["Reported"] = "Reported";
    ABUSCloudPortalCommonFeaturesFirmwareReleaseState["Publishing"] = "Publishing";
    ABUSCloudPortalCommonFeaturesFirmwareReleaseState["Published"] = "Published";
})(ABUSCloudPortalCommonFeaturesFirmwareReleaseState = exports.ABUSCloudPortalCommonFeaturesFirmwareReleaseState || (exports.ABUSCloudPortalCommonFeaturesFirmwareReleaseState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalCommonFeaturesSearchSearchResultType;
(function (ABUSCloudPortalCommonFeaturesSearchSearchResultType) {
    ABUSCloudPortalCommonFeaturesSearchSearchResultType["Devices"] = "Devices";
    ABUSCloudPortalCommonFeaturesSearchSearchResultType["ResellerProfiles"] = "ResellerProfiles";
})(ABUSCloudPortalCommonFeaturesSearchSearchResultType = exports.ABUSCloudPortalCommonFeaturesSearchSearchResultType || (exports.ABUSCloudPortalCommonFeaturesSearchSearchResultType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitBatteryReportReportFrequency;
(function (ABUSCloudPortalDexitBatteryReportReportFrequency) {
    ABUSCloudPortalDexitBatteryReportReportFrequency["EveryWeek"] = "EveryWeek";
    ABUSCloudPortalDexitBatteryReportReportFrequency["EveryMonth"] = "EveryMonth";
    ABUSCloudPortalDexitBatteryReportReportFrequency["Every2ndMonth"] = "Every2ndMonth";
    ABUSCloudPortalDexitBatteryReportReportFrequency["Every3rdMonth"] = "Every3rdMonth";
    ABUSCloudPortalDexitBatteryReportReportFrequency["Every4thMonth"] = "Every4thMonth";
    ABUSCloudPortalDexitBatteryReportReportFrequency["Every6thMonth"] = "Every6thMonth";
    ABUSCloudPortalDexitBatteryReportReportFrequency["EveryYear"] = "EveryYear";
})(ABUSCloudPortalDexitBatteryReportReportFrequency = exports.ABUSCloudPortalDexitBatteryReportReportFrequency || (exports.ABUSCloudPortalDexitBatteryReportReportFrequency = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitMessagesDexitConnectionState;
(function (ABUSCloudPortalDexitMessagesDexitConnectionState) {
    ABUSCloudPortalDexitMessagesDexitConnectionState["Disconnected"] = "Disconnected";
    ABUSCloudPortalDexitMessagesDexitConnectionState["Connected"] = "Connected";
    ABUSCloudPortalDexitMessagesDexitConnectionState["Unknown"] = "Unknown";
})(ABUSCloudPortalDexitMessagesDexitConnectionState = exports.ABUSCloudPortalDexitMessagesDexitConnectionState || (exports.ABUSCloudPortalDexitMessagesDexitConnectionState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitMessagesDexitControlDeviceType;
(function (ABUSCloudPortalDexitMessagesDexitControlDeviceType) {
    ABUSCloudPortalDexitMessagesDexitControlDeviceType["Master"] = "Master";
    ABUSCloudPortalDexitMessagesDexitControlDeviceType["Slave"] = "Slave";
})(ABUSCloudPortalDexitMessagesDexitControlDeviceType = exports.ABUSCloudPortalDexitMessagesDexitControlDeviceType || (exports.ABUSCloudPortalDexitMessagesDexitControlDeviceType = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus;
(function (ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus) {
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["UnknownStatus"] = "UnknownStatus";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Connecting"] = "Connecting";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Connected"] = "Connected";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Connectionlost"] = "Connectionlost";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Connectedbutwitherrors"] = "Connectedbutwitherrors";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Disconnecting"] = "Disconnecting";
    ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus["Deslaved"] = "Deslaved";
})(ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus = exports.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus || (exports.ABUSCloudPortalDexitMessagesDexitSlaveConnectionStatus = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitSettingsBatteryState;
(function (ABUSCloudPortalDexitSettingsBatteryState) {
    ABUSCloudPortalDexitSettingsBatteryState["Empty"] = "Empty";
    ABUSCloudPortalDexitSettingsBatteryState["Full"] = "Full";
    ABUSCloudPortalDexitSettingsBatteryState["Service"] = "Service";
    ABUSCloudPortalDexitSettingsBatteryState["Count"] = "Count";
    ABUSCloudPortalDexitSettingsBatteryState["Unknown"] = "Unknown";
})(ABUSCloudPortalDexitSettingsBatteryState = exports.ABUSCloudPortalDexitSettingsBatteryState || (exports.ABUSCloudPortalDexitSettingsBatteryState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitSettingsCylinderComponentTypes;
(function (ABUSCloudPortalDexitSettingsCylinderComponentTypes) {
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXCylinder"] = "WLXCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProAccessCylinder"] = "WLXProAccessCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProIntrusionCylinder"] = "WLXProIntrusionCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProAccessHalfCylinder"] = "WLXProAccessHalfCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProIntrusionHalfCylinder"] = "WLXProIntrusionHalfCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProAccessPanicCylinder"] = "WLXProAccessPanicCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProIntrusionPanicCylinder"] = "WLXProIntrusionPanicCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProAccessDoubleCylinder"] = "WLXProAccessDoubleCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProIntrusionDoubleCylinder"] = "WLXProIntrusionDoubleCylinder";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProAccessDoubleCylinderInner"] = "WLXProAccessDoubleCylinderInner";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["WLXProIntrusionDoubleCylinderInner"] = "WLXProIntrusionDoubleCylinderInner";
    ABUSCloudPortalDexitSettingsCylinderComponentTypes["Unknown"] = "Unknown";
})(ABUSCloudPortalDexitSettingsCylinderComponentTypes = exports.ABUSCloudPortalDexitSettingsCylinderComponentTypes || (exports.ABUSCloudPortalDexitSettingsCylinderComponentTypes = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalDexitSettingsSignalState;
(function (ABUSCloudPortalDexitSettingsSignalState) {
    ABUSCloudPortalDexitSettingsSignalState["Poor"] = "Poor";
    ABUSCloudPortalDexitSettingsSignalState["Good"] = "Good";
    ABUSCloudPortalDexitSettingsSignalState["Excellent"] = "Excellent";
    ABUSCloudPortalDexitSettingsSignalState["Unknown"] = "Unknown";
})(ABUSCloudPortalDexitSettingsSignalState = exports.ABUSCloudPortalDexitSettingsSignalState || (exports.ABUSCloudPortalDexitSettingsSignalState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalHyenLogsDirection;
(function (ABUSCloudPortalHyenLogsDirection) {
    ABUSCloudPortalHyenLogsDirection["Ascending"] = "Ascending";
    ABUSCloudPortalHyenLogsDirection["Descending"] = "Descending";
})(ABUSCloudPortalHyenLogsDirection = exports.ABUSCloudPortalHyenLogsDirection || (exports.ABUSCloudPortalHyenLogsDirection = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalHyenMessagesDeviceConnectionState;
(function (ABUSCloudPortalHyenMessagesDeviceConnectionState) {
    ABUSCloudPortalHyenMessagesDeviceConnectionState["Disconnected"] = "Disconnected";
    ABUSCloudPortalHyenMessagesDeviceConnectionState["Connected"] = "Connected";
    ABUSCloudPortalHyenMessagesDeviceConnectionState["Unknown"] = "Unknown";
    ABUSCloudPortalHyenMessagesDeviceConnectionState["Calling"] = "Calling";
})(ABUSCloudPortalHyenMessagesDeviceConnectionState = exports.ABUSCloudPortalHyenMessagesDeviceConnectionState || (exports.ABUSCloudPortalHyenMessagesDeviceConnectionState = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ABUSCloudPortalTectiqSettingsConnectionState;
(function (ABUSCloudPortalTectiqSettingsConnectionState) {
    ABUSCloudPortalTectiqSettingsConnectionState["Disconnected"] = "Disconnected";
    ABUSCloudPortalTectiqSettingsConnectionState["Connected"] = "Connected";
})(ABUSCloudPortalTectiqSettingsConnectionState = exports.ABUSCloudPortalTectiqSettingsConnectionState || (exports.ABUSCloudPortalTectiqSettingsConnectionState = {}));
/**
 * ComfionApi - axios parameter creator
 * @export
 */
exports.ComfionApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionComfionSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiComfionComfionSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/comfion/ComfionSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ABUSCloudPortalComfionFirmwareComfionPublishingData} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionFirmwarePublishingPublishPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/comfion/FirmwarePublishing/publish`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiComfionNotificationSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/comfion/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeDelete: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiComfionNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiComfionNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            const localVarPath = `/api/comfion/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeGet: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiComfionNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiComfionNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            const localVarPath = `/api/comfion/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiComfionNotificationSettingsDeviceIdPost.');
            }
            const localVarPath = `/api/comfion/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsNotificationsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/comfion/NotificationSettings/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ComfionApi - functional programming interface
 * @export
 */
exports.ComfionApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionComfionSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionComfionSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ABUSCloudPortalComfionFirmwareComfionPublishingData} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionFirmwarePublishingPublishPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionFirmwarePublishingPublishPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionNotificationSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsNotificationsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.ComfionApiAxiosParamCreator(configuration).apiComfionNotificationSettingsNotificationsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * ComfionApi - factory interface
 * @export
 */
exports.ComfionApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionComfionSettingsDeviceIdGet(deviceId, options) {
            return exports.ComfionApiFp(configuration).apiComfionComfionSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ABUSCloudPortalComfionFirmwareComfionPublishingData} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionFirmwarePublishingPublishPost(body, options) {
            return exports.ComfionApiFp(configuration).apiComfionFirmwarePublishingPublishPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdGet(deviceId, options) {
            return exports.ComfionApiFp(configuration).apiComfionNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return exports.ComfionApiFp(configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return exports.ComfionApiFp(configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return exports.ComfionApiFp(configuration).apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiComfionNotificationSettingsNotificationsGet(options) {
            return exports.ComfionApiFp(configuration).apiComfionNotificationSettingsNotificationsGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ComfionApi - object-oriented interface
 * @export
 * @class ComfionApi
 * @extends {BaseAPI}
 */
class ComfionApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionComfionSettingsDeviceIdGet(deviceId, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionComfionSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ABUSCloudPortalComfionFirmwareComfionPublishingData} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionFirmwarePublishingPublishPost(body, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionFirmwarePublishingPublishPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionNotificationSettingsDeviceIdGet(deviceId, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalComfionNotificationsNotificationTypeSettingsDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options) {
        return exports.ComfionApiFp(this.configuration).apiComfionNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComfionApi
     */
    apiComfionNotificationSettingsNotificationsGet(options) {
        return exports.ComfionApiFp(this.configuration).apiComfionNotificationSettingsNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ComfionApi = ComfionApi;
/**
 * CoreApi - axios parameter creator
 * @export
 */
exports.CoreApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdApprovePut: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdApprovePut.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}/approve`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdCheckGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdCheckGet.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}/check`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} extendAccessUntil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdExtendPut: (deviceId, extendAccessUntil, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdExtendPut.');
            }
            // verify required parameter 'extendAccessUntil' is not null or undefined
            if (extendAccessUntil === null || extendAccessUntil === undefined) {
                throw new base_1.RequiredError('extendAccessUntil', 'Required parameter extendAccessUntil was null or undefined when calling apiCoreDeviceAccessDeviceIdExtendPut.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}/extend`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (extendAccessUntil !== undefined) {
                localVarQueryParameter['extendAccessUntil'] = (extendAccessUntil instanceof Date) ?
                    extendAccessUntil.toISOString() :
                    extendAccessUntil;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdGet.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRequestaccessPost: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdRequestaccessPost.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}/requestaccess`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRevokePut: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceAccessDeviceIdRevokePut.');
            }
            const localVarPath = `/api/core/DeviceAccess/{deviceId}/revoke`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessLogErrorGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/DeviceAccess/log_error`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceOperatorMetadataDeviceIdGet.');
            }
            const localVarPath = `/api/core/DeviceOperatorMetadata/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreDeviceOperatorMetadataDeviceIdPost.');
            }
            const localVarPath = `/api/core/DeviceOperatorMetadata/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} [typeIdentifier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareGet: (typeIdentifier, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/Firmware`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (typeIdentifier !== undefined) {
                localVarQueryParameter['typeIdentifier'] = typeIdentifier;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} releaseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareReleaseIdSendnotificationPost: (releaseId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'releaseId' is not null or undefined
            if (releaseId === null || releaseId === undefined) {
                throw new base_1.RequiredError('releaseId', 'Required parameter releaseId was null or undefined when calling apiCoreFirmwareReleaseIdSendnotificationPost.');
            }
            const localVarPath = `/api/core/Firmware/{releaseId}/sendnotification`
                .replace(`{${"releaseId"}}`, encodeURIComponent(String(releaseId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareTypesGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/Firmware/types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreLocalizationDeviceIdGet.');
            }
            const localVarPath = `/api/core/Localization/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreLocalizationDeviceIdPost.');
            }
            const localVarPath = `/api/core/Localization/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationLanguagesGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/Localization/languages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet.');
            }
            const localVarPath = `/api/core/OperatorDeviceAssignments/{deviceId}/check`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreOperatorDeviceAssignmentsDeviceIdGet.');
            }
            const localVarPath = `/api/core/OperatorDeviceAssignments/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} operatorId
         * @param {number} [affectedOperatorId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete: (deviceId, operatorId, affectedOperatorId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete.');
            }
            // verify required parameter 'operatorId' is not null or undefined
            if (operatorId === null || operatorId === undefined) {
                throw new base_1.RequiredError('operatorId', 'Required parameter operatorId was null or undefined when calling apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete.');
            }
            const localVarPath = `/api/core/OperatorDeviceAssignments/{deviceId}/{operatorId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"operatorId"}}`, encodeURIComponent(String(operatorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (affectedOperatorId !== undefined) {
                localVarQueryParameter['affectedOperatorId'] = affectedOperatorId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete the operator profile for the provide custumer id.
         * @param {string} customerId The CustomerId for which data should be deleted from the cloud portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileCustomerIdDelete: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling apiCoreOperatorProfileCustomerIdDelete.');
            }
            const localVarPath = `/api/core/OperatorProfile/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/OperatorProfile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncCustomerIdPost: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling apiCoreOperatorProfileSyncCustomerIdPost.');
            }
            const localVarPath = `/api/core/OperatorProfile/sync/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Sync the data for the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncPut: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/OperatorProfile/sync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets reseller notes (text) for a given devices
         * @param {string} deviceId The device identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreResellerNotesDeviceIdGet.');
            }
            const localVarPath = `/api/core/ResellerNotes/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Stores reseller notes (any text) for a given device
         * @param {string} deviceId The device identifier
         * @param {string} [body] The text to be stored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreResellerNotesDeviceIdPost.');
            }
            const localVarPath = `/api/core/ResellerNotes/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/ResellerProfile`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncCustomerIdPost: (customerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'customerId' is not null or undefined
            if (customerId === null || customerId === undefined) {
                throw new base_1.RequiredError('customerId', 'Required parameter customerId was null or undefined when calling apiCoreResellerProfileSyncCustomerIdPost.');
            }
            const localVarPath = `/api/core/ResellerProfile/sync/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Sync the data for the company of the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncPut: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/ResellerProfile/sync`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdDeletePost: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreResetDeviceIdDeletePost.');
            }
            const localVarPath = `/api/core/Reset/{deviceId}/delete`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdResetPost: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCoreResetDeviceIdResetPost.');
            }
            const localVarPath = `/api/core/Reset/{deviceId}/reset`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ABUSCloudPortalCommonFeaturesSearchSearchRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreSearchPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/Search`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUserClaimsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/core/UserClaims`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * CoreApi - functional programming interface
 * @export
 */
exports.CoreApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} extendAccessUntil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessLogErrorGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceAccessLogErrorGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} [typeIdentifier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareGet(typeIdentifier, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreFirmwareGet(typeIdentifier, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} releaseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareTypesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreFirmwareTypesGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreLocalizationDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreLocalizationDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationLanguagesGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreLocalizationLanguagesGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} operatorId
         * @param {number} [affectedOperatorId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Delete the operator profile for the provide custumer id.
         * @param {string} customerId The CustomerId for which data should be deleted from the cloud portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileCustomerIdDelete(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorProfileCustomerIdDelete(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorProfileGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncCustomerIdPost(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorProfileSyncCustomerIdPost(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Sync the data for the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreOperatorProfileSyncPut(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Gets reseller notes (text) for a given devices
         * @param {string} deviceId The device identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResellerNotesDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Stores reseller notes (any text) for a given device
         * @param {string} deviceId The device identifier
         * @param {string} [body] The text to be stored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResellerNotesDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResellerProfileGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncCustomerIdPost(customerId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResellerProfileSyncCustomerIdPost(customerId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Sync the data for the company of the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncPut(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResellerProfileSyncPut(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdDeletePost(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResetDeviceIdDeletePost(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdResetPost(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreResetDeviceIdResetPost(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ABUSCloudPortalCommonFeaturesSearchSearchRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreSearchPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreSearchPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUserClaimsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.CoreApiAxiosParamCreator(configuration).apiCoreUserClaimsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * CoreApi - factory interface
 * @export
 */
exports.CoreApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} extendAccessUntil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceAccessLogErrorGet(options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceAccessLogErrorGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options) {
            return exports.CoreApiFp(configuration).apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [typeIdentifier]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareGet(typeIdentifier, options) {
            return exports.CoreApiFp(configuration).apiCoreFirmwareGet(typeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} releaseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options) {
            return exports.CoreApiFp(configuration).apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreFirmwareTypesGet(options) {
            return exports.CoreApiFp(configuration).apiCoreFirmwareTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreLocalizationDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationDeviceIdPost(deviceId, body, options) {
            return exports.CoreApiFp(configuration).apiCoreLocalizationDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreLocalizationLanguagesGet(options) {
            return exports.CoreApiFp(configuration).apiCoreLocalizationLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} operatorId
         * @param {number} [affectedOperatorId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete the operator profile for the provide custumer id.
         * @param {string} customerId The CustomerId for which data should be deleted from the cloud portal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileCustomerIdDelete(customerId, options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorProfileCustomerIdDelete(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileGet(options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncCustomerIdPost(customerId, options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorProfileSyncCustomerIdPost(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Sync the data for the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreOperatorProfileSyncPut(options) {
            return exports.CoreApiFp(configuration).apiCoreOperatorProfileSyncPut(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets reseller notes (text) for a given devices
         * @param {string} deviceId The device identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdGet(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreResellerNotesDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Stores reseller notes (any text) for a given device
         * @param {string} deviceId The device identifier
         * @param {string} [body] The text to be stored
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerNotesDeviceIdPost(deviceId, body, options) {
            return exports.CoreApiFp(configuration).apiCoreResellerNotesDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileGet(options) {
            return exports.CoreApiFp(configuration).apiCoreResellerProfileGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Sync the data for a specific Customer.  in order to execute this.
         * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncCustomerIdPost(customerId, options) {
            return exports.CoreApiFp(configuration).apiCoreResellerProfileSyncCustomerIdPost(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Sync the data for the company of the calling user. User needs to have a CustomerId claim.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResellerProfileSyncPut(options) {
            return exports.CoreApiFp(configuration).apiCoreResellerProfileSyncPut(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdDeletePost(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreResetDeviceIdDeletePost(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreResetDeviceIdResetPost(deviceId, options) {
            return exports.CoreApiFp(configuration).apiCoreResetDeviceIdResetPost(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ABUSCloudPortalCommonFeaturesSearchSearchRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreSearchPost(body, options) {
            return exports.CoreApiFp(configuration).apiCoreSearchPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreUserClaimsGet(options) {
            return exports.CoreApiFp(configuration).apiCoreUserClaimsGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
class CoreApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdApprovePut(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdCheckGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} extendAccessUntil
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdExtendPut(deviceId, extendAccessUntil, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdRequestaccessPost(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessDeviceIdRevokePut(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceAccessLogErrorGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceAccessLogErrorGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceOperatorMetadataDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalCommonFeaturesResellerDeviceOperatorMetadataDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options) {
        return exports.CoreApiFp(this.configuration).apiCoreDeviceOperatorMetadataDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [typeIdentifier]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreFirmwareGet(typeIdentifier, options) {
        return exports.CoreApiFp(this.configuration).apiCoreFirmwareGet(typeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} releaseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreFirmwareReleaseIdSendnotificationPost(releaseId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreFirmwareTypesGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreFirmwareTypesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreLocalizationDeviceIdGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreLocalizationDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalCommonFeaturesLocalizationLocalizationSettingDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreLocalizationDeviceIdPost(deviceId, body, options) {
        return exports.CoreApiFp(this.configuration).apiCoreLocalizationDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreLocalizationLanguagesGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreLocalizationLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorDeviceAssignmentsDeviceIdCheckGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorDeviceAssignmentsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} operatorId
     * @param {number} [affectedOperatorId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorDeviceAssignmentsDeviceIdOperatorIdDelete(deviceId, operatorId, affectedOperatorId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete the operator profile for the provide custumer id.
     * @param {string} customerId The CustomerId for which data should be deleted from the cloud portal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorProfileCustomerIdDelete(customerId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorProfileCustomerIdDelete(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorProfileGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorProfileGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Sync the data for a specific Customer.  in order to execute this.
     * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorProfileSyncCustomerIdPost(customerId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorProfileSyncCustomerIdPost(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Sync the data for the calling user. User needs to have a CustomerId claim.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreOperatorProfileSyncPut(options) {
        return exports.CoreApiFp(this.configuration).apiCoreOperatorProfileSyncPut(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets reseller notes (text) for a given devices
     * @param {string} deviceId The device identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResellerNotesDeviceIdGet(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreResellerNotesDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Stores reseller notes (any text) for a given device
     * @param {string} deviceId The device identifier
     * @param {string} [body] The text to be stored
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResellerNotesDeviceIdPost(deviceId, body, options) {
        return exports.CoreApiFp(this.configuration).apiCoreResellerNotesDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResellerProfileGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreResellerProfileGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Sync the data for a specific Customer.  in order to execute this.
     * @param {string} customerId The CustomerId for which data should be synced/updated from API Gateway
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResellerProfileSyncCustomerIdPost(customerId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreResellerProfileSyncCustomerIdPost(customerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Sync the data for the company of the calling user. User needs to have a CustomerId claim.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResellerProfileSyncPut(options) {
        return exports.CoreApiFp(this.configuration).apiCoreResellerProfileSyncPut(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResetDeviceIdDeletePost(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreResetDeviceIdDeletePost(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreResetDeviceIdResetPost(deviceId, options) {
        return exports.CoreApiFp(this.configuration).apiCoreResetDeviceIdResetPost(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ABUSCloudPortalCommonFeaturesSearchSearchRequestDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreSearchPost(body, options) {
        return exports.CoreApiFp(this.configuration).apiCoreSearchPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    apiCoreUserClaimsGet(options) {
        return exports.CoreApiFp(this.configuration).apiCoreUserClaimsGet(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.CoreApi = CoreApi;
/**
 * DexitApi - axios parameter creator
 * @export
 */
exports.DexitApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitBatteryReportDeviceIdGet.');
            }
            const localVarPath = `/api/dexit/BatteryReport/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitBatteryReportReportScheduleUpdateDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitBatteryReportDeviceIdPost.');
            }
            const localVarPath = `/api/dexit/BatteryReport/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {number} take
         * @param {number} skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet: (deviceId, take, skip, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet.');
            }
            // verify required parameter 'take' is not null or undefined
            if (take === null || take === undefined) {
                throw new base_1.RequiredError('take', 'Required parameter take was null or undefined when calling apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet.');
            }
            // verify required parameter 'skip' is not null or undefined
            if (skip === null || skip === undefined) {
                throw new base_1.RequiredError('skip', 'Required parameter skip was null or undefined when calling apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet.');
            }
            const localVarPath = `/api/dexit/DexitSettings/{deviceId}/components/{take}/{skip}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"take"}}`, encodeURIComponent(String(take)))
                .replace(`{${"skip"}}`, encodeURIComponent(String(skip)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdCylindercomponentsGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitDexitSettingsDeviceIdCylindercomponentsGet.');
            }
            const localVarPath = `/api/dexit/DexitSettings/{deviceId}/cylindercomponents`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitDexitSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/dexit/DexitSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingPublishPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dexit/FirmwarePublishing/publish`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingStopdistributionPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dexit/FirmwarePublishing/stopdistribution`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitNotificationSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/dexit/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeDelete: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiDexitNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            const localVarPath = `/api/dexit/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeGet: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiDexitNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            const localVarPath = `/api/dexit/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitNotificationSettingsDeviceIdPost.');
            }
            const localVarPath = `/api/dexit/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsNotificationsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dexit/NotificationSettings/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send Test Mail Notifications for any Dexit Device
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [subscribableNotificationType] Can be  dexit_tamper  dexit_battery  dexit_battery_count  dexit_battery_report  dexit_signal_loss  dexit_power_loss  dexit_power_back  cloud_connectivity_loss  If not set it will output all.
         * @param {string} [directNotificationType] Can be  dexit_access_granted  dexit_access_denied  dexit_armed  dexit_disarmed  dexit_tag_unknown  dexit_tamper  dexit_battery  dexit_battery_count  dexit_external_input  dexit_alarm_input  dexit_signal_loss  dexit_hotkey  dexit_power_loss  dexit_power_back  dexit_battery_report  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {boolean} [sendSubscribableNotifications] defaults: true
         * @param {boolean} [sendDirectNotificationsNotifications] defaults: true
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationTestingTestMailsGet: (deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiDexitNotificationTestingTestMailsGet.');
            }
            const localVarPath = `/api/dexit/NotificationTesting/TestMails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (subscribableNotificationType !== undefined) {
                localVarQueryParameter['subscribableNotificationType'] = subscribableNotificationType;
            }
            if (directNotificationType !== undefined) {
                localVarQueryParameter['directNotificationType'] = directNotificationType;
            }
            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }
            if (sendSubscribableNotifications !== undefined) {
                localVarQueryParameter['SendSubscribableNotifications'] = sendSubscribableNotifications;
            }
            if (sendDirectNotificationsNotifications !== undefined) {
                localVarQueryParameter['SendDirectNotificationsNotifications'] = sendDirectNotificationsNotifications;
            }
            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingPost: (pairingCode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dexit/OperatorPairing/pairing`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (pairingCode !== undefined) {
                localVarQueryParameter['pairingCode'] = pairingCode;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingcodeGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/dexit/OperatorPairing/pairingcode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DexitApi - functional programming interface
 * @export
 */
exports.DexitApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitBatteryReportDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitBatteryReportReportScheduleUpdateDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitBatteryReportDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {number} take
         * @param {number} skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitDexitSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingPublishPost(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitFirmwarePublishingPublishPost(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingStopdistributionPost(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitFirmwarePublishingStopdistributionPost(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsNotificationsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationSettingsNotificationsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Send Test Mail Notifications for any Dexit Device
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [subscribableNotificationType] Can be  dexit_tamper  dexit_battery  dexit_battery_count  dexit_battery_report  dexit_signal_loss  dexit_power_loss  dexit_power_back  cloud_connectivity_loss  If not set it will output all.
         * @param {string} [directNotificationType] Can be  dexit_access_granted  dexit_access_denied  dexit_armed  dexit_disarmed  dexit_tag_unknown  dexit_tamper  dexit_battery  dexit_battery_count  dexit_external_input  dexit_alarm_input  dexit_signal_loss  dexit_hotkey  dexit_power_loss  dexit_power_back  dexit_battery_report  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {boolean} [sendSubscribableNotifications] defaults: true
         * @param {boolean} [sendDirectNotificationsNotifications] defaults: true
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingPost(pairingCode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitOperatorPairingPairingPost(pairingCode, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingcodeGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.DexitApiAxiosParamCreator(configuration).apiDexitOperatorPairingPairingcodeGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * DexitApi - factory interface
 * @export
 */
exports.DexitApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdGet(deviceId, options) {
            return exports.DexitApiFp(configuration).apiDexitBatteryReportDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitBatteryReportReportScheduleUpdateDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitBatteryReportDeviceIdPost(deviceId, body, options) {
            return exports.DexitApiFp(configuration).apiDexitBatteryReportDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {number} take
         * @param {number} skip
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options) {
            return exports.DexitApiFp(configuration).apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options) {
            return exports.DexitApiFp(configuration).apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitDexitSettingsDeviceIdGet(deviceId, options) {
            return exports.DexitApiFp(configuration).apiDexitDexitSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingPublishPost(options) {
            return exports.DexitApiFp(configuration).apiDexitFirmwarePublishingPublishPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitFirmwarePublishingStopdistributionPost(options) {
            return exports.DexitApiFp(configuration).apiDexitFirmwarePublishingStopdistributionPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdGet(deviceId, options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationSettingsNotificationsGet(options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationSettingsNotificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send Test Mail Notifications for any Dexit Device
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [subscribableNotificationType] Can be  dexit_tamper  dexit_battery  dexit_battery_count  dexit_battery_report  dexit_signal_loss  dexit_power_loss  dexit_power_back  cloud_connectivity_loss  If not set it will output all.
         * @param {string} [directNotificationType] Can be  dexit_access_granted  dexit_access_denied  dexit_armed  dexit_disarmed  dexit_tag_unknown  dexit_tamper  dexit_battery  dexit_battery_count  dexit_external_input  dexit_alarm_input  dexit_signal_loss  dexit_hotkey  dexit_power_loss  dexit_power_back  dexit_battery_report  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {boolean} [sendSubscribableNotifications] defaults: true
         * @param {boolean} [sendDirectNotificationsNotifications] defaults: true
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options) {
            return exports.DexitApiFp(configuration).apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingPost(pairingCode, options) {
            return exports.DexitApiFp(configuration).apiDexitOperatorPairingPairingPost(pairingCode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDexitOperatorPairingPairingcodeGet(options) {
            return exports.DexitApiFp(configuration).apiDexitOperatorPairingPairingcodeGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DexitApi - object-oriented interface
 * @export
 * @class DexitApi
 * @extends {BaseAPI}
 */
class DexitApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitBatteryReportDeviceIdGet(deviceId, options) {
        return exports.DexitApiFp(this.configuration).apiDexitBatteryReportDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalDexitBatteryReportReportScheduleUpdateDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitBatteryReportDeviceIdPost(deviceId, body, options) {
        return exports.DexitApiFp(this.configuration).apiDexitBatteryReportDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {number} take
     * @param {number} skip
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options) {
        return exports.DexitApiFp(this.configuration).apiDexitDexitSettingsDeviceIdComponentsTakeSkipGet(deviceId, take, skip, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options) {
        return exports.DexitApiFp(this.configuration).apiDexitDexitSettingsDeviceIdCylindercomponentsGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitDexitSettingsDeviceIdGet(deviceId, options) {
        return exports.DexitApiFp(this.configuration).apiDexitDexitSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitFirmwarePublishingPublishPost(options) {
        return exports.DexitApiFp(this.configuration).apiDexitFirmwarePublishingPublishPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitFirmwarePublishingStopdistributionPost(options) {
        return exports.DexitApiFp(this.configuration).apiDexitFirmwarePublishingStopdistributionPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationSettingsDeviceIdGet(deviceId, options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalDexitNotificationsNotificationTypeSettingsDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationSettingsNotificationsGet(options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationSettingsNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send Test Mail Notifications for any Dexit Device
     * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
     * @param {string} [subscribableNotificationType] Can be  dexit_tamper  dexit_battery  dexit_battery_count  dexit_battery_report  dexit_signal_loss  dexit_power_loss  dexit_power_back  cloud_connectivity_loss  If not set it will output all.
     * @param {string} [directNotificationType] Can be  dexit_access_granted  dexit_access_denied  dexit_armed  dexit_disarmed  dexit_tag_unknown  dexit_tamper  dexit_battery  dexit_battery_count  dexit_external_input  dexit_alarm_input  dexit_signal_loss  dexit_hotkey  dexit_power_loss  dexit_power_back  dexit_battery_report  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
     * @param {boolean} [sendSubscribableNotifications] defaults: true
     * @param {boolean} [sendDirectNotificationsNotifications] defaults: true
     * @param {string} [recipient] Will fallback to calling users email if not set explicitly
     * @param {string} [language] en, de, es, fr, nl, da, sv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options) {
        return exports.DexitApiFp(this.configuration).apiDexitNotificationTestingTestMailsGet(deviceId, subscribableNotificationType, directNotificationType, sendSubscribableNotifications, sendDirectNotificationsNotifications, recipient, language, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [pairingCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitOperatorPairingPairingPost(pairingCode, options) {
        return exports.DexitApiFp(this.configuration).apiDexitOperatorPairingPairingPost(pairingCode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DexitApi
     */
    apiDexitOperatorPairingPairingcodeGet(options) {
        return exports.DexitApiFp(this.configuration).apiDexitOperatorPairingPairingcodeGet(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DexitApi = DexitApi;
/**
 * HyenApi - axios parameter creator
 * @export
 */
exports.HyenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} notificationType added  removed
         * @param {string} deviceId The identifier for the existing device.
         * @param {string} deviceName The name used to identifiy the device.
         * @param {number} currentOperatorId The operator who performed the action (currently logged in operator).
         * @param {string} recipient The recipient for the test mail.
         * @param {number} [affectedOperatorId] The operator who is affected in case of a removal.
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonOperatorNotificationTestingTestMailsGet: (notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiCommonOperatorNotificationTestingTestMailsGet.');
            }
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiCommonOperatorNotificationTestingTestMailsGet.');
            }
            // verify required parameter 'deviceName' is not null or undefined
            if (deviceName === null || deviceName === undefined) {
                throw new base_1.RequiredError('deviceName', 'Required parameter deviceName was null or undefined when calling apiCommonOperatorNotificationTestingTestMailsGet.');
            }
            // verify required parameter 'currentOperatorId' is not null or undefined
            if (currentOperatorId === null || currentOperatorId === undefined) {
                throw new base_1.RequiredError('currentOperatorId', 'Required parameter currentOperatorId was null or undefined when calling apiCommonOperatorNotificationTestingTestMailsGet.');
            }
            // verify required parameter 'recipient' is not null or undefined
            if (recipient === null || recipient === undefined) {
                throw new base_1.RequiredError('recipient', 'Required parameter recipient was null or undefined when calling apiCommonOperatorNotificationTestingTestMailsGet.');
            }
            const localVarPath = `/api/common/OperatorNotificationTesting/TestMails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (notificationType !== undefined) {
                localVarQueryParameter['notificationType'] = notificationType;
            }
            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }
            if (deviceName !== undefined) {
                localVarQueryParameter['deviceName'] = deviceName;
            }
            if (currentOperatorId !== undefined) {
                localVarQueryParameter['currentOperatorId'] = currentOperatorId;
            }
            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }
            if (affectedOperatorId !== undefined) {
                localVarQueryParameter['affectedOperatorId'] = affectedOperatorId;
            }
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdEmailDelete: (deviceId, email, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenAppPairingsDeviceIdEmailDelete.');
            }
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new base_1.RequiredError('email', 'Required parameter email was null or undefined when calling apiHyenAppPairingsDeviceIdEmailDelete.');
            }
            const localVarPath = `/api/hyen/AppPairings/{deviceId}/{email}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenAppPairingsDeviceIdGet.');
            }
            const localVarPath = `/api/hyen/AppPairings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} firmwareTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost: (firmwareTypeId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'firmwareTypeId' is not null or undefined
            if (firmwareTypeId === null || firmwareTypeId === undefined) {
                throw new base_1.RequiredError('firmwareTypeId', 'Required parameter firmwareTypeId was null or undefined when calling apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost.');
            }
            const localVarPath = `/api/hyen/FirmwarePublishing/{firmwareTypeId}/stopdistribution`
                .replace(`{${"firmwareTypeId"}}`, encodeURIComponent(String(firmwareTypeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingPublishPost: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/hyen/FirmwarePublishing/publish`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenHyenSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenHyenSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/hyen/HyenSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenLogsHyenLogQueryFilter} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenLogsDeviceIdLogsPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenLogsDeviceIdLogsPost.');
            }
            const localVarPath = `/api/hyen/Logs/{deviceId}/logs`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenNotificationSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/hyen/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeDelete: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiHyenNotificationSettingsDeviceIdNotificationTypeDelete.');
            }
            const localVarPath = `/api/hyen/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeGet: (deviceId, notificationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            // verify required parameter 'notificationType' is not null or undefined
            if (notificationType === null || notificationType === undefined) {
                throw new base_1.RequiredError('notificationType', 'Required parameter notificationType was null or undefined when calling apiHyenNotificationSettingsDeviceIdNotificationTypeGet.');
            }
            const localVarPath = `/api/hyen/NotificationSettings/{deviceId}/{notificationType}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"notificationType"}}`, encodeURIComponent(String(notificationType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdPost: (deviceId, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenNotificationSettingsDeviceIdPost.');
            }
            const localVarPath = `/api/hyen/NotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Return a list of available Notifications that Resellers can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsNotificationsGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/hyen/NotificationSettings/notifications`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [notificationType] Can be  hyen_rf_supervision  hyen_rf_jamming  hyen_rf_battery_psu  hyen_panel_battery  hyen_panel_mains  hyen_faults  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationTestingTestMailsGet: (deviceId, notificationType, recipient, language, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiHyenNotificationTestingTestMailsGet.');
            }
            const localVarPath = `/api/hyen/NotificationTesting/TestMails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (notificationType !== undefined) {
                localVarQueryParameter['notificationType'] = notificationType;
            }
            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }
            if (recipient !== undefined) {
                localVarQueryParameter['recipient'] = recipient;
            }
            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenOperatorPairingPairingPost: (pairingCode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/hyen/OperatorPairing/pairing`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (pairingCode !== undefined) {
                localVarQueryParameter['pairingCode'] = pairingCode;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Returns info if the app can use new CIAM
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCIAMUseCiamGet: (version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionCIAMUseCiamGet.');
            }
            const localVarPath = `/api/v{version}/CIAM/useCiam`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Deletes mobile device settings for an appinstance of the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsDelete: (appInstanceId, version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'appInstanceId' is not null or undefined
            if (appInstanceId === null || appInstanceId === undefined) {
                throw new base_1.RequiredError('appInstanceId', 'Required parameter appInstanceId was null or undefined when calling apiVversionMobileDeviceSettingsDelete.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionMobileDeviceSettingsDelete.');
            }
            const localVarPath = `/api/v{version}/MobileDeviceSettings`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (appInstanceId !== undefined) {
                localVarQueryParameter['appInstanceId'] = appInstanceId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets a list of mobile device settings for the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsGet: (appInstanceId, version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'appInstanceId' is not null or undefined
            if (appInstanceId === null || appInstanceId === undefined) {
                throw new base_1.RequiredError('appInstanceId', 'Required parameter appInstanceId was null or undefined when calling apiVversionMobileDeviceSettingsGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionMobileDeviceSettingsGet.');
            }
            const localVarPath = `/api/v{version}/MobileDeviceSettings`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (appInstanceId !== undefined) {
                localVarQueryParameter['appInstanceId'] = appInstanceId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Creates or updates mobile device settings
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppUpdateMobileDeviceSettingsDto} [body] Data used to update mobile device settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsPut: (version, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionMobileDeviceSettingsPut.');
            }
            const localVarPath = `/api/v{version}/MobileDeviceSettings`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets the push notification configuration for the current user and the given device id.  All enabled notification collections/groups are returned
         * @param {string} deviceId The device identifier for which the notification will be triggered
         * @param {string} appInstanceId The AppInstance Guid for which the notifications are configured
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsDeviceIdGet: (deviceId, appInstanceId, version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiVversionPanelNotificationSettingsDeviceIdGet.');
            }
            // verify required parameter 'appInstanceId' is not null or undefined
            if (appInstanceId === null || appInstanceId === undefined) {
                throw new base_1.RequiredError('appInstanceId', 'Required parameter appInstanceId was null or undefined when calling apiVversionPanelNotificationSettingsDeviceIdGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelNotificationSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/v{version}/PanelNotificationSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (appInstanceId !== undefined) {
                localVarQueryParameter['appInstanceId'] = appInstanceId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsNotificationCollectionsGet: (version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelNotificationSettingsNotificationCollectionsGet.');
            }
            const localVarPath = `/api/v{version}/PanelNotificationSettings/notificationCollections`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Creates the push notification configuration for the current user and the given device id  The NotificationCollectionNames property is a list of all enabled notification collections (groups) every group not listed here is disabled  The endpoint \'/api/v1/PanelNotificationSettings/notificationCollections returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppNotificationsNotificationCollectionConfigurationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsPost: (version, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelNotificationSettingsPost.');
            }
            const localVarPath = `/api/v{version}/PanelNotificationSettings`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} appInstanceId
         * @param {string} panelId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsAppInstanceIdPanelIdDelete: (appInstanceId, panelId, version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'appInstanceId' is not null or undefined
            if (appInstanceId === null || appInstanceId === undefined) {
                throw new base_1.RequiredError('appInstanceId', 'Required parameter appInstanceId was null or undefined when calling apiVversionPanelsAppInstanceIdPanelIdDelete.');
            }
            // verify required parameter 'panelId' is not null or undefined
            if (panelId === null || panelId === undefined) {
                throw new base_1.RequiredError('panelId', 'Required parameter panelId was null or undefined when calling apiVversionPanelsAppInstanceIdPanelIdDelete.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelsAppInstanceIdPanelIdDelete.');
            }
            const localVarPath = `/api/v{version}/panels/{appInstanceId}/{panelId}`
                .replace(`{${"appInstanceId"}}`, encodeURIComponent(String(appInstanceId)))
                .replace(`{${"panelId"}}`, encodeURIComponent(String(panelId)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} appInstanceId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsGet: (appInstanceId, version, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'appInstanceId' is not null or undefined
            if (appInstanceId === null || appInstanceId === undefined) {
                throw new base_1.RequiredError('appInstanceId', 'Required parameter appInstanceId was null or undefined when calling apiVversionPanelsGet.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelsGet.');
            }
            const localVarPath = `/api/v{version}/panels`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            if (appInstanceId !== undefined) {
                localVarQueryParameter['appInstanceId'] = appInstanceId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppPairingAppPairingRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsPairPut: (version, body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new base_1.RequiredError('version', 'Required parameter version was null or undefined when calling apiVversionPanelsPairPut.');
            }
            const localVarPath = `/api/v{version}/panels/pair`
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} [deviceId]
         * @param {string} [panelPasscode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPanelsPairingcodeGet: (deviceId, panelPasscode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/internal/panels/pairingcode`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (deviceId !== undefined) {
                localVarQueryParameter['deviceId'] = deviceId;
            }
            if (panelPasscode !== undefined) {
                localVarQueryParameter['panelPasscode'] = panelPasscode;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * HyenApi - functional programming interface
 * @export
 */
exports.HyenApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} notificationType added  removed
         * @param {string} deviceId The identifier for the existing device.
         * @param {string} deviceName The name used to identifiy the device.
         * @param {number} currentOperatorId The operator who performed the action (currently logged in operator).
         * @param {string} recipient The recipient for the test mail.
         * @param {number} [affectedOperatorId] The operator who is affected in case of a removal.
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenAppPairingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} firmwareTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingPublishPost(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenFirmwarePublishingPublishPost(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenHyenSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenHyenSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenLogsHyenLogQueryFilter} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenLogsDeviceIdLogsPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenLogsDeviceIdLogsPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Return a list of available Notifications that Resellers can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsNotificationsGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationSettingsNotificationsGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [notificationType] Can be  hyen_rf_supervision  hyen_rf_jamming  hyen_rf_battery_psu  hyen_panel_battery  hyen_panel_mains  hyen_faults  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenOperatorPairingPairingPost(pairingCode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiHyenOperatorPairingPairingPost(pairingCode, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Returns info if the app can use new CIAM
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCIAMUseCiamGet(version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionCIAMUseCiamGet(version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Deletes mobile device settings for an appinstance of the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Gets a list of mobile device settings for the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsGet(appInstanceId, version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionMobileDeviceSettingsGet(appInstanceId, version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Creates or updates mobile device settings
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppUpdateMobileDeviceSettingsDto} [body] Data used to update mobile device settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsPut(version, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionMobileDeviceSettingsPut(version, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Gets the push notification configuration for the current user and the given device id.  All enabled notification collections/groups are returned
         * @param {string} deviceId The device identifier for which the notification will be triggered
         * @param {string} appInstanceId The AppInstance Guid for which the notifications are configured
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @summary Creates the push notification configuration for the current user and the given device id  The NotificationCollectionNames property is a list of all enabled notification collections (groups) every group not listed here is disabled  The endpoint \'/api/v1/PanelNotificationSettings/notificationCollections returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppNotificationsNotificationCollectionConfigurationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsPost(version, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelNotificationSettingsPost(version, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} appInstanceId
         * @param {string} panelId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} appInstanceId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsGet(appInstanceId, version, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelsGet(appInstanceId, version, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppPairingAppPairingRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsPairPut(version, body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).apiVversionPanelsPairPut(version, body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {string} [deviceId]
         * @param {string} [panelPasscode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPanelsPairingcodeGet(deviceId, panelPasscode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.HyenApiAxiosParamCreator(configuration).internalPanelsPairingcodeGet(deviceId, panelPasscode, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * HyenApi - factory interface
 * @export
 */
exports.HyenApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} notificationType added  removed
         * @param {string} deviceId The identifier for the existing device.
         * @param {string} deviceName The name used to identifiy the device.
         * @param {number} currentOperatorId The operator who performed the action (currently logged in operator).
         * @param {string} recipient The recipient for the test mail.
         * @param {number} [affectedOperatorId] The operator who is affected in case of a removal.
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options) {
            return exports.HyenApiFp(configuration).apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options) {
            return exports.HyenApiFp(configuration).apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenAppPairingsDeviceIdGet(deviceId, options) {
            return exports.HyenApiFp(configuration).apiHyenAppPairingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} firmwareTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options) {
            return exports.HyenApiFp(configuration).apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenFirmwarePublishingPublishPost(options) {
            return exports.HyenApiFp(configuration).apiHyenFirmwarePublishingPublishPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenHyenSettingsDeviceIdGet(deviceId, options) {
            return exports.HyenApiFp(configuration).apiHyenHyenSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenLogsHyenLogQueryFilter} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenLogsDeviceIdLogsPost(deviceId, body, options) {
            return exports.HyenApiFp(configuration).apiHyenLogsDeviceIdLogsPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdGet(deviceId, options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {string} notificationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} deviceId
         * @param {ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Return a list of available Notifications that Resellers can subscribe to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationSettingsNotificationsGet(options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationSettingsNotificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send Test Mails for Hyen Devices
         * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
         * @param {string} [notificationType] Can be  hyen_rf_supervision  hyen_rf_jamming  hyen_rf_battery_psu  hyen_panel_battery  hyen_panel_mains  hyen_faults  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
         * @param {string} [recipient] Will fallback to calling users email if not set explicitly
         * @param {string} [language] en, de, es, fr, nl, da, sv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options) {
            return exports.HyenApiFp(configuration).apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [pairingCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHyenOperatorPairingPairingPost(pairingCode, options) {
            return exports.HyenApiFp(configuration).apiHyenOperatorPairingPairingPost(pairingCode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns info if the app can use new CIAM
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionCIAMUseCiamGet(version, options) {
            return exports.HyenApiFp(configuration).apiVversionCIAMUseCiamGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Deletes mobile device settings for an appinstance of the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options) {
            return exports.HyenApiFp(configuration).apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a list of mobile device settings for the calling user
         * @param {string} appInstanceId The unique AppInstance the settings are stored for
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsGet(appInstanceId, version, options) {
            return exports.HyenApiFp(configuration).apiVversionMobileDeviceSettingsGet(appInstanceId, version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates or updates mobile device settings
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppUpdateMobileDeviceSettingsDto} [body] Data used to update mobile device settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionMobileDeviceSettingsPut(version, body, options) {
            return exports.HyenApiFp(configuration).apiVversionMobileDeviceSettingsPut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets the push notification configuration for the current user and the given device id.  All enabled notification collections/groups are returned
         * @param {string} deviceId The device identifier for which the notification will be triggered
         * @param {string} appInstanceId The AppInstance Guid for which the notifications are configured
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Creates the push notification configuration for the current user and the given device id  The NotificationCollectionNames property is a list of all enabled notification collections (groups) every group not listed here is disabled  The endpoint \'/api/v1/PanelNotificationSettings/notificationCollections returns a list of all valid NotificationCollectionNames
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppNotificationsNotificationCollectionConfigurationDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelNotificationSettingsPost(version, body, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelNotificationSettingsPost(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} appInstanceId
         * @param {string} panelId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} appInstanceId
         * @param {string} version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsGet(appInstanceId, version, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelsGet(appInstanceId, version, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} version
         * @param {ABUSCloudPortalHyenAppPairingAppPairingRequestDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVversionPanelsPairPut(version, body, options) {
            return exports.HyenApiFp(configuration).apiVversionPanelsPairPut(version, body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [deviceId]
         * @param {string} [panelPasscode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        internalPanelsPairingcodeGet(deviceId, panelPasscode, options) {
            return exports.HyenApiFp(configuration).internalPanelsPairingcodeGet(deviceId, panelPasscode, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * HyenApi - object-oriented interface
 * @export
 * @class HyenApi
 * @extends {BaseAPI}
 */
class HyenApi extends base_1.BaseAPI {
    /**
     *
     * @summary Send Test Mails for Hyen Devices
     * @param {string} notificationType added  removed
     * @param {string} deviceId The identifier for the existing device.
     * @param {string} deviceName The name used to identifiy the device.
     * @param {number} currentOperatorId The operator who performed the action (currently logged in operator).
     * @param {string} recipient The recipient for the test mail.
     * @param {number} [affectedOperatorId] The operator who is affected in case of a removal.
     * @param {string} [language] en, de, es, fr, nl, da, sv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options) {
        return exports.HyenApiFp(this.configuration).apiCommonOperatorNotificationTestingTestMailsGet(notificationType, deviceId, deviceName, currentOperatorId, recipient, affectedOperatorId, language, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options) {
        return exports.HyenApiFp(this.configuration).apiHyenAppPairingsDeviceIdEmailDelete(deviceId, email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenAppPairingsDeviceIdGet(deviceId, options) {
        return exports.HyenApiFp(this.configuration).apiHyenAppPairingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} firmwareTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options) {
        return exports.HyenApiFp(this.configuration).apiHyenFirmwarePublishingFirmwareTypeIdStopdistributionPost(firmwareTypeId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenFirmwarePublishingPublishPost(options) {
        return exports.HyenApiFp(this.configuration).apiHyenFirmwarePublishingPublishPost(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenHyenSettingsDeviceIdGet(deviceId, options) {
        return exports.HyenApiFp(this.configuration).apiHyenHyenSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalHyenLogsHyenLogQueryFilter} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenLogsDeviceIdLogsPost(deviceId, body, options) {
        return exports.HyenApiFp(this.configuration).apiHyenLogsDeviceIdLogsPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationSettingsDeviceIdGet(deviceId, options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeDelete(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {string} notificationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationSettingsDeviceIdNotificationTypeGet(deviceId, notificationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} deviceId
     * @param {ABUSCloudPortalHyenNotificationsNotificationTypeSettingsDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationSettingsDeviceIdPost(deviceId, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Return a list of available Notifications that Resellers can subscribe to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationSettingsNotificationsGet(options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationSettingsNotificationsGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send Test Mails for Hyen Devices
     * @param {string} deviceId Used to get OperatorMetadata and Notification-Language for the given device.
     * @param {string} [notificationType] Can be  hyen_rf_supervision  hyen_rf_jamming  hyen_rf_battery_psu  hyen_panel_battery  hyen_panel_mains  hyen_faults  cloud_connectivity_loss  cloud_connectivity_restore  If not set it will output all.
     * @param {string} [recipient] Will fallback to calling users email if not set explicitly
     * @param {string} [language] en, de, es, fr, nl, da, sv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options) {
        return exports.HyenApiFp(this.configuration).apiHyenNotificationTestingTestMailsGet(deviceId, notificationType, recipient, language, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [pairingCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiHyenOperatorPairingPairingPost(pairingCode, options) {
        return exports.HyenApiFp(this.configuration).apiHyenOperatorPairingPairingPost(pairingCode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Returns info if the app can use new CIAM
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionCIAMUseCiamGet(version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionCIAMUseCiamGet(version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Deletes mobile device settings for an appinstance of the calling user
     * @param {string} appInstanceId The unique AppInstance the settings are stored for
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionMobileDeviceSettingsDelete(appInstanceId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets a list of mobile device settings for the calling user
     * @param {string} appInstanceId The unique AppInstance the settings are stored for
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionMobileDeviceSettingsGet(appInstanceId, version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionMobileDeviceSettingsGet(appInstanceId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Creates or updates mobile device settings
     * @param {string} version
     * @param {ABUSCloudPortalHyenAppUpdateMobileDeviceSettingsDto} [body] Data used to update mobile device settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionMobileDeviceSettingsPut(version, body, options) {
        return exports.HyenApiFp(this.configuration).apiVversionMobileDeviceSettingsPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets the push notification configuration for the current user and the given device id.  All enabled notification collections/groups are returned
     * @param {string} deviceId The device identifier for which the notification will be triggered
     * @param {string} appInstanceId The AppInstance Guid for which the notifications are configured
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelNotificationSettingsDeviceIdGet(deviceId, appInstanceId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Returns a list of all valid NotificationCollectionNames
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelNotificationSettingsNotificationCollectionsGet(version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Creates the push notification configuration for the current user and the given device id  The NotificationCollectionNames property is a list of all enabled notification collections (groups) every group not listed here is disabled  The endpoint \'/api/v1/PanelNotificationSettings/notificationCollections returns a list of all valid NotificationCollectionNames
     * @param {string} version
     * @param {ABUSCloudPortalHyenAppNotificationsNotificationCollectionConfigurationDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelNotificationSettingsPost(version, body, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelNotificationSettingsPost(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} appInstanceId
     * @param {string} panelId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelsAppInstanceIdPanelIdDelete(appInstanceId, panelId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} appInstanceId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelsGet(appInstanceId, version, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelsGet(appInstanceId, version, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} version
     * @param {ABUSCloudPortalHyenAppPairingAppPairingRequestDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    apiVversionPanelsPairPut(version, body, options) {
        return exports.HyenApiFp(this.configuration).apiVversionPanelsPairPut(version, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} [deviceId]
     * @param {string} [panelPasscode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HyenApi
     */
    internalPanelsPairingcodeGet(deviceId, panelPasscode, options) {
        return exports.HyenApiFp(this.configuration).internalPanelsPairingcodeGet(deviceId, panelPasscode, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.HyenApi = HyenApi;
/**
 * TectiqApi - axios parameter creator
 * @export
 */
exports.TectiqApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqTectiqSettingsDeviceIdGet: (deviceId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'deviceId' is not null or undefined
            if (deviceId === null || deviceId === undefined) {
                throw new base_1.RequiredError('deviceId', 'Required parameter deviceId was null or undefined when calling apiTectiqTectiqSettingsDeviceIdGet.');
            }
            const localVarPath = `/api/tectiq/TectiqSettings/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ABUSCloudPortalTectiqAdminAppLightUploadDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqUploadPost: (body, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tectiq/upload`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappControlTimeGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tectiq/v1/cloud_adminapp/control_time`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} fwDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappFwFwDataIdGet: (fwDataId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fwDataId' is not null or undefined
            if (fwDataId === null || fwDataId === undefined) {
                throw new base_1.RequiredError('fwDataId', 'Required parameter fwDataId was null or undefined when calling apiTectiqV1CloudAdminappFwFwDataIdGet.');
            }
            const localVarPath = `/api/tectiq/v1/cloud_adminapp/fw/{fw_data_id}`
                .replace(`{${"fw_data_id"}}`, encodeURIComponent(String(fwDataId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappJobAdhocListGet: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/tectiq/v1/cloud_adminapp/job_adhoc_list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("oauth2", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TectiqApi - functional programming interface
 * @export
 */
exports.TectiqApiFp = function (configuration) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqTectiqSettingsDeviceIdGet(deviceId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.TectiqApiAxiosParamCreator(configuration).apiTectiqTectiqSettingsDeviceIdGet(deviceId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {ABUSCloudPortalTectiqAdminAppLightUploadDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqUploadPost(body, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.TectiqApiAxiosParamCreator(configuration).apiTectiqUploadPost(body, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappControlTimeGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.TectiqApiAxiosParamCreator(configuration).apiTectiqV1CloudAdminappControlTimeGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {number} fwDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.TectiqApiAxiosParamCreator(configuration).apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappJobAdhocListGet(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield exports.TectiqApiAxiosParamCreator(configuration).apiTectiqV1CloudAdminappJobAdhocListGet(options);
                return (axios = axios_1.default, basePath = base_1.BASE_PATH) => {
                    const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                    return axios.request(axiosRequestArgs);
                };
            });
        },
    };
};
/**
 * TectiqApi - factory interface
 * @export
 */
exports.TectiqApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {string} deviceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqTectiqSettingsDeviceIdGet(deviceId, options) {
            return exports.TectiqApiFp(configuration).apiTectiqTectiqSettingsDeviceIdGet(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ABUSCloudPortalTectiqAdminAppLightUploadDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqUploadPost(body, options) {
            return exports.TectiqApiFp(configuration).apiTectiqUploadPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappControlTimeGet(options) {
            return exports.TectiqApiFp(configuration).apiTectiqV1CloudAdminappControlTimeGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} fwDataId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options) {
            return exports.TectiqApiFp(configuration).apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTectiqV1CloudAdminappJobAdhocListGet(options) {
            return exports.TectiqApiFp(configuration).apiTectiqV1CloudAdminappJobAdhocListGet(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TectiqApi - object-oriented interface
 * @export
 * @class TectiqApi
 * @extends {BaseAPI}
 */
class TectiqApi extends base_1.BaseAPI {
    /**
     *
     * @param {string} deviceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TectiqApi
     */
    apiTectiqTectiqSettingsDeviceIdGet(deviceId, options) {
        return exports.TectiqApiFp(this.configuration).apiTectiqTectiqSettingsDeviceIdGet(deviceId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ABUSCloudPortalTectiqAdminAppLightUploadDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TectiqApi
     */
    apiTectiqUploadPost(body, options) {
        return exports.TectiqApiFp(this.configuration).apiTectiqUploadPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TectiqApi
     */
    apiTectiqV1CloudAdminappControlTimeGet(options) {
        return exports.TectiqApiFp(this.configuration).apiTectiqV1CloudAdminappControlTimeGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} fwDataId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TectiqApi
     */
    apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options) {
        return exports.TectiqApiFp(this.configuration).apiTectiqV1CloudAdminappFwFwDataIdGet(fwDataId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TectiqApi
     */
    apiTectiqV1CloudAdminappJobAdhocListGet(options) {
        return exports.TectiqApiFp(this.configuration).apiTectiqV1CloudAdminappJobAdhocListGet(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TectiqApi = TectiqApi;
