import { getPortalConfiguration } from '../getPortalConfiguration';
import UserProfileStore, { IUserProfileStoreState } from './userprofile/store';
import axios, { AxiosRequestConfig } from 'axios';

export interface IAccessIdentityStoreState {
    userProfileStore: IUserProfileStoreState;
}

export class AccessIdentityStore {
    public userProfileStore: UserProfileStore;

    constructor(initialState?: IAccessIdentityStoreState) {
        this.userProfileStore = new UserProfileStore(initialState?.userProfileStore);
    }

    public async sendPasswordResetEmail(email: string): Promise<void> {
        const clientId = getPortalConfiguration().clientId;
        const domain = getPortalConfiguration().loginBaseUrl;

        const options: AxiosRequestConfig = {
            method: 'POST',
            url: `${domain}/dbconnections/change_password`,
            headers: { 'content-type': 'application/json' },
            data: {
                client_id: clientId,
                email: email,
                connection: 'Username-Password-Authentication'
            }
        };

        await axios.request(options);
    }

    public toJson(): IAccessIdentityStoreState {
        try {
            const plain: IAccessIdentityStoreState = {
                userProfileStore: this.userProfileStore.toJSON()
            };
            return plain;
        } catch (e: any) {
            throw new Error('[Serialization Error]');
        }
    }
}
