export interface IEnvConfig {
    AUTH0: {
        AUDIENCE_PORTAL: string;
        CLIENTID: string;
        CLIENT_SECRET: string;
        CALLBACK_URL: string;
        DOMAIN: string;
    };
    CACHE_REFRESH_SECONDS: number;
    CORS_ALLOWEDORIGIN: string;
    CALINGA_INCLUDE_DRAFTS: string;
    INTERNAL_BFF_BASE_URL: string;
    INTERNAL_SCS_BASE_URL: string;
    LOGOUT_CALLBACK_URL: string;
    REDIS_ENDPOINT: string;
    REDIS_PORT: number;
    SESSION: {
        MAXAGE_SECONDS: number;
    };
    PORT: string | number;
    LOG_LEVEL: string;
    LOG_ENABLED: boolean;
    NODE_ENV: string | undefined;
    ENV: {
        TAG: string | undefined;
        UI: {
            NAME: string | undefined;
            COLOR: string | undefined;
            LOGO: string | undefined;
        };
    };
}

export const getEnvConfig = (): IEnvConfig => {
    const CACHE_REFRESH_SECONDS = process.env.CACHE_REFRESH_SECONDS && Number.isInteger(Number(process.env.CACHE_REFRESH_SECONDS)) ? Number(process.env.CACHE_REFRESH_SECONDS) : 180;
    const CORS_ALLOWEDORIGIN = process.env.CORS_ALLOWEDORIGIN || 'https://abus-cloud.com';
    const INTERNAL_BFF_BASE_URL = process.env.INTERNAL_BFF_BASE_URL!;
    const INTERNAL_SCS_BASE_URL = process.env.INTERNAL_SCS_BASE_URL!;
    const LOGOUT_CALLBACK_URL = process.env.LOGOUT_CALLBACK_URL!;
    const REDIS_ENDPOINT = process.env.REDIS_ENDPOINT || 'redis';
    const REDIS_PORT = Number(process.env.RedisPort) || 6379;
    const SESSION_MAXAGE = Number(process.env.SESSION_MAXAGE_SECONDS) || 35999;
    const PORT = process.env.PORT || 3000;
    const LOG_LEVEL = process.env.PORTAL_LOG_LEVEL || 'info';
    const LOG_ENABLED = process.env.PORTAL_LOG_ENABLED !== '0';
    const NODE_ENV = process.env.NODE_ENV;
    const CALINGA_INCLUDE_DRAFTS = process.env.CALINGA_INCLUDE_DRAFTS || 'true';

    const AUTH0 = {
        AUDIENCE_PORTAL: process.env.CIAM_AUDIENCE!,
        CLIENTID: process.env.CIAM_CLIENTID!,
        CLIENT_SECRET: process.env.CIAM_CLIENT_SECRET!,
        CALLBACK_URL: process.env.CIAM_CALLBACK_URL!,
        DOMAIN: process.env.CIAM_DOMAIN!
    };
            
    const ENV = {
        TAG: process.env.ENV_TAG,
        UI: {
            NAME: process.env.ENV_UISTAMP_NAME,
            COLOR: process.env.ENV_UISTAMP_COLOR,
            LOGO: process.env.ENV_UISTAMP_LOGO
        }
    };

    const settings: IEnvConfig = {
        CACHE_REFRESH_SECONDS,
        CORS_ALLOWEDORIGIN,
        CALINGA_INCLUDE_DRAFTS,
        INTERNAL_BFF_BASE_URL,
        INTERNAL_SCS_BASE_URL,
        LOGOUT_CALLBACK_URL,
        REDIS_ENDPOINT,
        REDIS_PORT,
        PORT,
        LOG_LEVEL,
        LOG_ENABLED,
        NODE_ENV,
        AUTH0,
        ENV,
        SESSION: {
            MAXAGE_SECONDS: SESSION_MAXAGE
        }
    };

    return settings;
};
