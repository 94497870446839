import React, { useState, useEffect } from 'react';
import { useStore } from '../../common/stores/useStore';
import { defaultLogger } from '../../../logger';
import { useTranslation } from 'react-i18next';
import './notes.scss';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { Skeleton } from '../../common/components/skeleton';

export const Notes: React.FC = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);
    const rootStore = useStore();
    const deviceStore = rootStore.deviceStore;

    const [notes, setNotes] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [savedNotes, setSavedNotes] = useState<string>('');

    useEffect(() => {
        const loadNotes = async () => {
            try {
                setIsLoading(true);
                const existingNotes = await deviceStore.getDeviceNotes(deviceStore.deviceId!);
                if (existingNotes) {
                    setNotes(existingNotes as string);
                    setSavedNotes(existingNotes as string);
                }
            } catch (error) {
                defaultLogger.error('Failed to load existing notes for deviceId', deviceStore.deviceId, error);
            }
            setIsLoading(false);
        };

        loadNotes();
    }, [deviceStore]);

    const handleSave = async () => {
        try {
            await deviceStore.postDeviceNotes(deviceStore.deviceId!, notes);
            setSavedNotes(notes);
            setIsEditing(false);
        } catch (error) {
            defaultLogger.error('Failed to save notes for deviceId', deviceStore.deviceId, error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setNotes(savedNotes);
    };

    return (
        <div>
            {isLoading
                ? (
                    <Skeleton style={{ height: '150px' }}></Skeleton>
                )
                : isEditing
                    ? (
                        <div>
                            <textarea className='mb-3 notes-text' value={notes} onChange={(e) => setNotes(e.target.value)} rows={10} cols={100} placeholder='' style={{ fontWeight: 'normal' }} />
                            <div>
                                <button className='btn btn-primary mr-1' onClick={handleSave}>
                                    {t('Devices.SaveNotes')}
                                </button>
                                <button className='btn btn-secondary' onClick={handleCancel}>
                                    {t('Devices.CancelSaveNotes')}
                                </button>
                            </div>
                        </div>
                    )
                    : (
                        <div>
                            <p className='notes-text'>{notes || t('Devices.NoExistingNotes')}</p>
                            <button className='btn btn-primary' onClick={() => setIsEditing(true)}>
                                {t('Devices.EditNotes')}
                            </button>
                        </div>
                    )}
        </div>
    );
};
