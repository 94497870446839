import React, { useEffect, useRef } from 'react';
import './cookies.scss';
import './../../../assets/index.scss';

export const Cookies = () => {
    const policyContainerRef = useRef(null);

    useEffect(() => {
        if (policyContainerRef.current && typeof OneTrust !== 'undefined' && typeof OneTrust.initializeCookiePolicyHtml === 'function') {
            // Let OneTrust populate the content without React interference
            OneTrust.initializeCookiePolicyHtml();
        }
    }, []);

    return (
        <div className="cookies">
            <div className="container cookies-mwidth pt-5">
                <section className="">
                    <div id="ot-sdk-cookie-policy" ref={policyContainerRef}></div>
                </section>
            </div>
        </div>
    );
};