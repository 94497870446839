import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { KnownRoutes } from '../../routing/knownroutes';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../stores/useStore';
import { isValidEnvValue } from '../isValidEnvValue';

import './footer.scss';

export interface IFooterProps {
    hidden: boolean;
}

const Footer = (props: IFooterProps) => {
    const { viewStore } = useStore();
    const { t } = useTranslation('portal_footer');

    if (props.hidden) {
        return null;
    }

    const { color } = viewStore.envStamping;
    const footerStyle: React.CSSProperties = {};

    if (isValidEnvValue(color)) {
        footerStyle.backgroundColor = color;
    }

    return (
        <footer className="app-footer container-fluid justify-content-center" style={footerStyle}>
            <div className="d-flex flex-wrap">
                <div className="p-1 flex-grow-md-0 flex-grow-1">
                    <span className="typo-t2 text-white">{t('CopyrightNotice')}</span>
                </div>
                <span>
                    {' '}
                    <a className="btn" style={{ opacity: 0 }} onClick={() => viewStore.removeTimers()}>
                        stop auto-reload
                    </a>
                </span>
                <div>
                    <NavLink exact={false} className="btn btn-link no-hover border-0 p-1 m-0" to={KnownRoutes.Cookies}>
                        <span className="typo-t2 text-white">{t('Cookies')}</span>
                    </NavLink>

                    <span className="p-1 typo-t2 text-white">|</span>

                    <NavLink exact={false} className="btn btn-link no-hover border-0 p-1 m-0" to={KnownRoutes.Imprint}>
                        <span className="typo-t2 text-white">{t('Imprint')}</span>
                    </NavLink>

                    <span className="p-1 typo-t2 text-white">|</span>

                    <NavLink exact={false} className="btn btn-link no-hover border-0 p-1 m-0" to={KnownRoutes.Policy}>
                        <span className="typo-t2 text-white">{t('Policy')}</span>
                    </NavLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
