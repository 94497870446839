import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProfileRow from './profileRow';
import { UserProfileModel } from '../store';
import { InputType } from 'reactstrap/types/lib/Input';
import { TRANSLATIONS_NAMESPACE } from '../../index';
import Input from '../../../common/components/input';
import { useObserver } from 'mobx-react';
import { useStore } from '../../../common/stores/useStore';
import { getRootStore } from '../../../storeRegistry';
import { KnownRoles } from '../../../common/routing/knownroles';

export const ProfileForm = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const { accessIdentityStore } = useStore();

    return useObserver(() => {
        const { userProfileStore } = accessIdentityStore;
        const { viewStore } = getRootStore();
        const isUserOperator = viewStore.user.hasRoles(KnownRoles.Operator);

        const renderInput = <K extends keyof UserProfileModel>(key: K, placeholderLabelKey: string, type?: InputType, helpLabelKey?: string, readOnly?: boolean) => {
            const model = isUserOperator ? userProfileStore!.operatorProfile : userProfileStore.userProfile;
            const value: any = model != null ? model[key] : '';
            readOnly = true;
            type = type || 'text';

            return (
                <Input
                    type={type}
                    id={key as string}
                    name={key as string}
                    placeholder={t(placeholderLabelKey).toString()}
                    value={value}
                    help={helpLabelKey ? t(helpLabelKey).toString() : undefined}
                    readOnly={readOnly}
                    isLoading={userProfileStore!.isLoading}
                />
            );
        };

        return (
            <div>
                <ProfileRow>
                    <div className="col-12">{renderInput('email', 'Form.Email')}</div>
                    <div className="col-12">{renderInput('lastName', 'Form.LastName')}</div>
                    <div className="col-12">{renderInput('firstName', 'Form.FirstName')}</div>
                </ProfileRow>
            </div>
        );
    });
};
