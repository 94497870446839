import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useObserver } from 'mobx-react';
import Avatar from '../../common/components/avatar';
import ProfileRow from './userProfileForm/profileRow';
import { Tabs } from '../../common/components/tab/tabs';
import { TRANSLATIONS_NAMESPACE } from '../index';
import { useStore } from '../../common/stores/useStore';
import { createTabsConfiguration } from './createTabsConfiguration';

export const UserDetails = () => {
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
    const { accessIdentityStore, viewStore } = useStore();

    const handlePasswordReset = async () => {
        try {
            await accessIdentityStore.sendPasswordResetEmail(viewStore.user.portalAuthorization.email!);
            viewStore.notifySuccess(t('Profile.SendResetPasswordMailSuccess'));
        } catch (error: any) {
            viewStore.notifyError(t('Profile.SendResetPasswordMailError'), error);
        }
    };

    return useObserver(() => {
        const { userProfileStore } = accessIdentityStore;
        const { userProfile } = userProfileStore;

        return (
            <main className="userprofileComponent row pb-3">
                <section className="first col-lg-6 pt-2">
                    <ProfileRow className="col-12 pb-5 ml-2">
                        <Avatar firstname={userProfile ? userProfile.firstName : ''} lastname={userProfile ? userProfile.lastName : ''} />
                    </ProfileRow>
                    <ProfileRow>
                        <div className="col-lg-6">
                            <button
                                className="btn btn-change-password btn-secondary w-100"
                                onClick={handlePasswordReset}
                            >{t('Profile.SendResetPasswordMail')}
                            </button>
                            <span className="typo-c1">{t('Profile.SendResetPasswordMailInfoText')}</span>
                        </div>
                    </ProfileRow>
                    <br />
                </section>
                <section className="second col-lg-6">
                    <ProfileRow>
                        <div className="col-12">
                            <Tabs tabs={createTabsConfiguration({ viewStore, t })} />
                        </div>
                    </ProfileRow>
                </section>
            </main >
        );
    });
};