import { action, computed, observable } from 'mobx';
import { generatePath } from 'react-router';
import { getRootStore, getViewStore } from '../../storeRegistry';
import { ViewStore } from '../../common/stores/viewStore';
import RootStore from '../../common/stores/rootStore';
import DeviceStore, { DeviceType, LoadOptions } from './deviceStore';
import { IDeviceDetailsRouteParams } from '../../common/routing/interfaces';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { KnownRoles } from '../../common/routing/knownroles';
import { IViewStore } from '../../common/stores/viewStore.inerface';
import { createLoggerWithNamespace } from '../../../logger';
import { DeviceFeatures, DeviceModel } from './deviceStore/deviceModel';
import { IModelWithTimeUpdates } from '../../common/stores/modelWithTimeUpdates.interface';
import { HyenDeviceModel } from './deviceStore/hyenDeviceModel';

const logger = createLoggerWithNamespace('DevicesViewStore');

export interface IDevicesTabModel {
    name: string;
    exact: boolean;
    path: KnownRoutes[];
    displayOrder: number;
    isVisible: boolean;
    onSelected: () => void;
}

export const NotificationSource = 'devicesviewstore';

export class DevicesViewStore implements IViewStore, IModelWithTimeUpdates {
    private static readonly _contextUris = [
        KnownRoutes.HyenDevice,
        KnownRoutes.HyenDeviceLogs,
        KnownRoutes.HyenDeviceAppPairings,
        KnownRoutes.HyenDeviceRemoteConnect,
        KnownRoutes.HyenNotificationSettings,
        KnownRoutes.HyenDeviceSettings,
        KnownRoutes.HyenOperatorAdministratorPairings,
        KnownRoutes.HyenDeviceNotes,

        KnownRoutes.DexitDevice,
        KnownRoutes.DexitNotificationSettings,
        KnownRoutes.DexitDeviceSettings,
        KnownRoutes.DexitDeviceComponents,
        KnownRoutes.DexitWLXManager,
        KnownRoutes.DexitOperatorAdministratorPairings,
        KnownRoutes.DexitDeviceNotes,

        KnownRoutes.ComfionDevice,
        KnownRoutes.ComfionDeviceRemoteConnect,
        KnownRoutes.ComfionNotificationSettings,
        KnownRoutes.ComfionDeviceSettings,
        KnownRoutes.ComfionDeviceNotes
    ];

    private _reloadSharedData = true;

    private getDexitTabs(deviceModel: DeviceModel): IDevicesTabModel[] {
        const tabs: IDevicesTabModel[] = [
            {
                path: [KnownRoutes.DexitDeviceComponents],
                displayOrder: 0,
                exact: true,
                name: 'ComponentsTab',
                isVisible: deviceModel.supportsFeature(DeviceFeatures.Components) ?? false,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDexitDeviceComponents({ deviceid: deviceModel.deviceId ?? '', deviceType: DeviceType.Dexit });
                }
            },
            {
                path: [KnownRoutes.DexitWLXManager],
                exact: true,
                displayOrder: 2,
                name: 'WLXManagerTab',
                isVisible: deviceModel.supportsFeature(DeviceFeatures.WLXManager) ?? false,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDexitWLXManager({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Dexit });
                }
            },
            {
                path: [KnownRoutes.DexitDeviceNotes],
                exact: true,
                displayOrder: 3,
                name: 'NotesTab',
                isVisible: this.applicationViewStore.user.hasRoles(KnownRoles.Reseller),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotes({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Dexit });
                }
            },
            {
                path: [KnownRoutes.DexitNotificationSettings],
                exact: true,
                displayOrder: 4,
                name: 'NotificationSettingsTab',
                isVisible: !this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotifications({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Dexit });
                }
            },
            {
                path: [KnownRoutes.DexitDeviceSettings],
                exact: true,
                displayOrder: 5,
                name: 'SettingsTab',
                isVisible: !this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceSettings({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Dexit });
                }
            },
            {
                path: [KnownRoutes.DexitOperatorAdministratorPairings],
                exact: true,
                displayOrder: 6,
                name: 'AdministratorPairings',
                isVisible: this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToOperatorAdministratorPairings({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Dexit });
                }
            }
        ];
        return tabs.sort((x, y) => x.displayOrder - y.displayOrder);
    }

    private getHyenTabs(deviceModel: DeviceModel): IDevicesTabModel[] {
        console.log(JSON.stringify(this.applicationViewStore.user));
        const tabs: IDevicesTabModel[] = [
            {
                path: [KnownRoutes.HyenDeviceLogs],
                displayOrder: 1,
                exact: true,
                name: 'LogsTab',
                isVisible: deviceModel.supportsFeature(DeviceFeatures.Logs) ?? false,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToHyenDeviceLogs({ deviceid: deviceModel.deviceId ?? '', deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenDeviceRemoteConnect],
                displayOrder: 2,
                exact: true,
                name: 'RemoteTab',
                isVisible: deviceModel.supportsFeature(DeviceFeatures.RemoteConnect) ?? false,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToHyenDeviceRemoteConnect({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenDeviceAppPairings],
                displayOrder: 3,
                exact: true,
                name: 'UsersTab',
                isVisible: deviceModel.supportsFeature(DeviceFeatures.AppPairings) ?? false,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToHyenAppPairings({ deviceid: deviceModel.deviceId ?? '', deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenDeviceNotes],
                exact: true,
                displayOrder: 4,
                name: 'NotesTab',
                isVisible: this.applicationViewStore.user.hasRoles(KnownRoles.Reseller),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotes({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenNotificationSettings],
                exact: true,
                displayOrder: 5,
                name: 'NotificationSettingsTab',
                isVisible: !this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotifications({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenDeviceSettings],
                exact: true,
                displayOrder: 6,
                name: 'SettingsTab',
                isVisible: !this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceSettings({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Hyen });
                }
            },
            {
                path: [KnownRoutes.HyenOperatorAdministratorPairings],
                exact: true,
                displayOrder: 7,
                name: 'AdministratorPairings',
                isVisible: this.applicationViewStore.user.hasRoles(KnownRoles.Operator),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToOperatorAdministratorPairings({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Hyen });
                }
            }
        ];

        return tabs;
    }

    private getComfionTabs(deviceModel: DeviceModel): IDevicesTabModel[] {
        const tabs: IDevicesTabModel[] = [
            {
                path: [KnownRoutes.ComfionDeviceRemoteConnect],
                displayOrder: 1,
                exact: true,
                name: 'RemoteTab',
                isVisible: true,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToComfionDeviceRemoteConnect({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Comfion });
                }
            },
            {
                path: [KnownRoutes.ComfionNotificationSettings],
                exact: true,
                displayOrder: 2,
                name: 'NotificationSettingsTab',
                isVisible: true,
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotifications({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Comfion });
                }
            },
            {
                path: [KnownRoutes.ComfionDeviceSettings],
                exact: true,
                displayOrder: 3,
                name: 'SettingsTab',
                isVisible: true,
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceSettings({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Comfion });
                }
            },
            {
                path: [KnownRoutes.ComfionDeviceNotes],
                exact: true,
                displayOrder: 4,
                name: 'NotesTab',
                isVisible: this.applicationViewStore.user.hasRoles(KnownRoles.Reseller),
                onSelected: () => {
                    this.applicationViewStore.devicesViewStore.navigateToDeviceNotes({ deviceid: deviceModel.deviceId, deviceType: DeviceType.Comfion });
                }
            }
        ];

        return tabs;
    }

    private allTabs(deviceModel?: DeviceModel) {
        if (deviceModel == null) {
            return [];
        }
        if (deviceModel.type === DeviceType.Dexit) {
            return this.getDexitTabs(deviceModel);
        }
        if (deviceModel.type === DeviceType.Hyen) {
            return this.getHyenTabs(deviceModel);
        }
        if (deviceModel.type === DeviceType.Comfion) {
            return this.getComfionTabs(deviceModel);
        }
        return [];
    }

    @observable tabs: IDevicesTabModel[] | null = null;

    private _lastKnownDeviceType: DeviceType = DeviceType.Unknown;

    @computed get lastKnownDeviceType(): DeviceType {
        const type = this.deviceStore.deviceDetails.data?.type;
        if (type !== DeviceType.Unknown && type != null) {
            this._lastKnownDeviceType = type;
        }
        return this._lastKnownDeviceType;
    }

    @computed get title(): string | undefined {
        const device = this.deviceStore.deviceDetails.data;

        let header: string | undefined;
        if (device?.operatorMetadata.projectName != null && device?.operatorMetadata?.projectName.length > 0) {
            return device?.operatorMetadata?.projectName;
        }

        if (this.deviceStore.deviceDetails.data instanceof HyenDeviceModel && this.deviceStore.deviceDetails.data.siteId != null && this.deviceStore.deviceDetails.data.siteId !== '') {
            return this.deviceStore.deviceDetails.data.siteId;
        }

        if (device?.deviceId != null) {
            return device?.deviceId;
        }
        return header;
    }

    public async initialize(): Promise<void> {
        logger.debug('initialize');
    }

    get applicationViewStore(): ViewStore {
        return getViewStore();
    }

    get rootStore(): RootStore {
        return getRootStore();
    }

    get deviceStore(): DeviceStore {
        return getRootStore().deviceStore;
    }

    public async initStateForPath(path: KnownRoutes, routeParams: Record<string, any>, deviceType: DeviceType = DeviceType.Unknown): Promise<void> {
        if (DevicesViewStore._contextUris.find((x) => x === path) != null) {
            if (this.applicationViewStore.loadRemoteContentOnInit) {
                try {
                    const initializeRoute = await this.preInitDeviceRoutes(path, routeParams as IDeviceDetailsRouteParams, deviceType);
                    if (deviceType === DeviceType.Dexit) {
                        await this.initializeRoute(routeParams as IDeviceDetailsRouteParams, {
                            loadBaseDeviceData: false,
                            baseDataRefresh: true,
                            settings: initializeRoute === KnownRoutes.DexitDeviceSettings,
                            notificationSettings: initializeRoute === KnownRoutes.DexitNotificationSettings,
                            components: initializeRoute === KnownRoutes.DexitDeviceComponents
                        });
                    }
                    if (deviceType === DeviceType.Hyen) {
                        await this.initializeRoute(routeParams as IDeviceDetailsRouteParams, {
                            loadBaseDeviceData: false,
                            baseDataRefresh: true,
                            settings: initializeRoute === KnownRoutes.HyenDeviceSettings,
                            notificationSettings: initializeRoute === KnownRoutes.HyenNotificationSettings,
                            appPairings: initializeRoute === KnownRoutes.HyenDeviceAppPairings,
                            logs: initializeRoute === KnownRoutes.HyenDeviceLogs
                        });
                    }
                    if (deviceType === DeviceType.Comfion) {
                        await this.initializeRoute(routeParams as IDeviceDetailsRouteParams, {
                            loadBaseDeviceData: false,
                            baseDataRefresh: true,
                            settings: initializeRoute === KnownRoutes.ComfionDeviceSettings,
                            notificationSettings: initializeRoute === KnownRoutes.ComfionNotificationSettings
                        });
                    }
                } catch (e) {
                    this.applicationViewStore.handleInitializationErrors(e);
                }
            } else {
                await this.rootStore.deviceStore.setContext({ deviceId: routeParams.deviceid });
            }
            logger.warn('Could not find _contextUris for path ', path);
        }
    }

    public async initStateForPathCompleted(path: KnownRoutes): Promise<void> {
        logger.debug('devicesViewStore.initStateForPathCompleted', path);
        if (!this.isContextPath(path as string)) {
            logger.debug('initStateForPathCompleted:isContextPath?', false);
            this.rootStore.deviceStore.setRefreshOptions({
                components: false,
                settings: false,
                notificationSettings: false,
                appPairings: false,
                logs: false,
                baseDataRefresh: false
            });
            this._reloadSharedData = true;
        } else {
            logger.debug('initStateForPathCompleted:isContextPath?', true);
        }
    }

    private async setPath(knownRoute: KnownRoutes, routeParams: Record<string, any>) {
        const path = generatePath(knownRoute as string, routeParams);
        this.applicationViewStore.currentPath = path;
    }

    @action.bound
    public async navigateToDevice(params: IDeviceDetailsRouteParams) {
        switch (params.deviceType) {
        case DeviceType.Dexit:
            await this.setPath(KnownRoutes.DexitDevice, params);
            break;
        case DeviceType.Hyen:
            await this.setPath(KnownRoutes.HyenDevice, params);
            break;
        case DeviceType.Aoc:
            await this.setPath(KnownRoutes.AocDevice, params);
            break;
        case DeviceType.Comfion:
            await this.setPath(KnownRoutes.ComfionDevice, params);
            break;
        default:
            break;
        }
    }

    @action.bound
    public async navigateToDeviceNotifications(params: IDeviceDetailsRouteParams) {
        switch (params.deviceType) {
        case DeviceType.Dexit:
            await this.setPath(KnownRoutes.DexitNotificationSettings, params);
            break;
        case DeviceType.Hyen:
            await this.setPath(KnownRoutes.HyenNotificationSettings, params);
            break;
        case DeviceType.Comfion:
            await this.setPath(KnownRoutes.ComfionNotificationSettings, params);
            break;
        case DeviceType.Aoc:
            break;
        default:
            break;
        }
    }

    @action.bound
    public async navigateToDeviceSettings(params: IDeviceDetailsRouteParams) {
        switch (params.deviceType) {
        case DeviceType.Dexit:
            await this.setPath(KnownRoutes.DexitDeviceSettings, params);
            break;
        case DeviceType.Hyen:
            await this.setPath(KnownRoutes.HyenDeviceSettings, params);
            break;
        case DeviceType.Comfion:
            await this.setPath(KnownRoutes.ComfionDeviceSettings, params);
            break;
        case DeviceType.Aoc:
            break;
        default:
            break;
        }
    }

    @action.bound
    public async navigateToDeviceNotes(params: IDeviceDetailsRouteParams) {
        switch (params.deviceType) {
        case DeviceType.Dexit:
            await this.setPath(KnownRoutes.DexitDeviceNotes, params);
            break;
        case DeviceType.Hyen:
            await this.setPath(KnownRoutes.HyenDeviceNotes, params);
            break;
        case DeviceType.Comfion:
            await this.setPath(KnownRoutes.ComfionDeviceNotes, params);
            break;
        case DeviceType.Aoc:
            break;
        default:
            break;
        }
    }

    @action.bound
    public async navigateToOperatorAdministratorPairings(params: IDeviceDetailsRouteParams) {
        switch (params.deviceType) {
        case DeviceType.Dexit:
            await this.setPath(KnownRoutes.DexitOperatorAdministratorPairings, params);
            break;
        case DeviceType.Hyen:
            await this.setPath(KnownRoutes.HyenOperatorAdministratorPairings, params);
            break;
        case DeviceType.Comfion:
            break;
        case DeviceType.Aoc:
            break;
        default:
            break;
        }
    }

    @action.bound
    public async navigateToDexitDeviceComponents(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.DexitDeviceComponents, params);
    }

    @action.bound
    public async navigateToHyenDeviceLogs(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.HyenDeviceLogs, params);
    }

    @action.bound
    public async navigateToHyenAppPairings(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.HyenDeviceAppPairings, params);
    }

    @action.bound
    public async navigateToHyenDeviceRemoteConnect(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.HyenDeviceRemoteConnect, params);
    }

    @action.bound
    public async navigateToDexitWLXManager(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.DexitWLXManager, params);
    }

    @action.bound
    public async navigateToComfionDeviceRemoteConnect(params: IDeviceDetailsRouteParams) {
        await this.setPath(KnownRoutes.ComfionDeviceRemoteConnect, params);
    }

    @action.bound
    public timeChanged(): void {
        this.checkDeviceAccessValidity();
    }

    private isContextPath(path: string | null) {
        return DevicesViewStore._contextUris.find((x) => x === path) != null;
    }

    private async checkDeviceAccessValidity(): Promise<void> {
        const viewStore = this.applicationViewStore;

        if (this.isContextPath(viewStore.currentRoute?.path ?? null) && viewStore.user.hasRoles(KnownRoles.TechnicalSupport)) {
            if (this.deviceStore.deviceDetails.data?.deviceAccess.accessTimeLeft === 0) {
                logger.debug('go - away!');
                this.applicationViewStore.navigateToSearch();
                this.applicationViewStore.notifyError(NotificationSource, 'deviceaccessexpired');
            }
        }
    }

    private async preInitDeviceRoutes(route: KnownRoutes, params: IDeviceDetailsRouteParams, deviceType: DeviceType): Promise<KnownRoutes> {
        let initialRoute = route;
        try {
            if (params.deviceid !== this.deviceStore.deviceId) {
                logger.debug('preInitDeviceRoutes (device change)');
                this._reloadSharedData = true;
                this.tabs = null;
                this.deviceStore.setContext({ deviceId: params.deviceid });
            }
            logger.debug('preInitDeviceRoutes', { reloadSharedData: this._reloadSharedData });
            await this.deviceStore.get({ deviceId: params.deviceid, deviceType: deviceType }, { loadBaseDeviceData: this._reloadSharedData, loadOnly: true, baseDataRefresh: true });
            const tabs = this.allTabs(this.deviceStore.deviceDetails.data ?? undefined);

            initialRoute = this.calculateInitialRoute(tabs, initialRoute, deviceType);

            this.tabs = tabs;
            this._reloadSharedData = false;
        } catch (e: any) {
            this.applicationViewStore.handleInitializationErrors(e);
        }
        return initialRoute;
    }

    private calculateInitialRoute(tabs: IDevicesTabModel[], initialRoute: KnownRoutes, deviceType: DeviceType) {
        const firstVisible = tabs.find((x) => x.isVisible);
        const pathOfFirstVisible = firstVisible?.path[0];

        if (firstVisible != null && pathOfFirstVisible != null) {
            if (deviceType === DeviceType.Dexit) {
                firstVisible.path.push(KnownRoutes.DexitDevice);
                if (initialRoute === KnownRoutes.DexitDevice) {
                    initialRoute = pathOfFirstVisible;
                }
            }
            if (deviceType === DeviceType.Hyen) {
                firstVisible.path.push(KnownRoutes.HyenDevice);
                if (initialRoute === KnownRoutes.HyenDevice) {
                    initialRoute = pathOfFirstVisible;
                }
            }
            if (deviceType === DeviceType.Aoc) {
                firstVisible.path.push(KnownRoutes.AocDevice);
                if (initialRoute === KnownRoutes.AocDevice) {
                    initialRoute = pathOfFirstVisible;
                }
            }
            if (deviceType === DeviceType.Comfion) {
                firstVisible.path.push(KnownRoutes.ComfionDevice);
                if (initialRoute === KnownRoutes.ComfionDevice) {
                    initialRoute = pathOfFirstVisible;
                }
            }
        }

        return initialRoute;
    }

    private async initializeRoute(params: IDeviceDetailsRouteParams, loadOptions: LoadOptions) {
        await this.rootStore.deviceStore.get({ deviceId: params.deviceid, deviceType: params.deviceType }, loadOptions);
    }
}
