import { useObserver } from 'mobx-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS_NAMESPACE_DEVICE } from '..';
import { LabeledData } from '../../common/components/labeledData/labeledData';
import { useStore } from '../../common/stores/useStore';
import { assertState } from '../../common/utils/modelState';
import { DeviceIcon } from '../layout/icon/deviceIcon';
import { HyenDeviceModel } from '../stores/deviceStore/hyenDeviceModel';
import { DexitProperties } from './properties/dexitProperties';
import { HyenProperties } from './properties/hyenProperties';
import { ComfionProperties } from './properties/comfionProperties';
import { ComfionDeviceModel } from '../stores/deviceStore/comfionDeviceModel';
import Button from '../../common/components/button';
import './properties.scss';
import { ModalPopup } from '../../common/components/modalPopup/modalPopup';
import { DeviceType } from '../stores/deviceStore';
import HtmlEditor from 'devextreme-react/html-editor';

export const Properties = () => {
    const {
        deviceStore,
        viewStore: { devicesViewStore }
    } = useStore();
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE_DEVICE);

    return useObserver(() => {
        const { deviceDetails } = deviceStore;
        const isLoading = assertState(deviceDetails.state, 'initial', 'loading');
        const [isShowReleaseNotesModalOpen, setIsShowReleaseNotesModalOpen] = useState(false);

        const handleCloseAddDeviceModal = () => {
            setIsShowReleaseNotesModalOpen(false);
        };

        const getReleaseNotesContent = (deviceType: DeviceType | undefined) => {
            switch (deviceType) {
            case DeviceType.Aoc:
                return t('Device.ReleaseNotes.Aoc');
            case DeviceType.Comfion:
                return t('Device.ReleaseNotes.Comfion');
            case DeviceType.Dexit:
                return t('Device.ReleaseNotes.Dexit');
            case DeviceType.Hyen:
                return t('Device.ReleaseNotes.Hyen');
            case DeviceType.Unknown:
            default:
                return t('Device.ReleaseNotes.Unknown');
            }
        };

        return (
            <React.Fragment>
                <DeviceIcon iconSize={100} deviceType={deviceDetails?.data?.type} deviceState={deviceDetails.data?.state} deviceSubType={deviceDetails?.data?.deviceSubType} />
                <br />
                <LabeledData labelName={t('Devices.DeviceName')} data={deviceDetails.data?.deviceName} showSkeleton={isLoading} />
                <LabeledData labelName={t('Devices.SerialNumber')} data={deviceDetails.data?.serialNumber} showSkeleton={isLoading} />
                <LabeledData labelName={t('Devices.FirmwareVersion')} data={deviceDetails.data?.firmwareVersion} showSkeleton={isLoading} />
                {!isLoading && (
                    <Button type='button' style='link' className='btn btn-link show-release-notes' onClick={() => setIsShowReleaseNotesModalOpen(true)}>
                        {t('Device.ReleaseNotes.Show') + ' >'}
                    </Button>
                )}
                <LabeledData labelName={t('Devices.IPAddress')} data={deviceDetails.data?.ipAddress} showSkeleton={isLoading} />
                {devicesViewStore.lastKnownDeviceType === 'Dexit' && <DexitProperties showSkeleton={isLoading} device={deviceDetails.data} />}
                {devicesViewStore.lastKnownDeviceType === 'Hyen' && <HyenProperties showSkeleton={isLoading} device={deviceDetails.data as HyenDeviceModel} />}
                {devicesViewStore.lastKnownDeviceType === 'Comfion' && <ComfionProperties showSkeleton={isLoading} device={deviceDetails.data as ComfionDeviceModel} />}

                <ModalPopup size='xl' title={t('Device.FirmwareReleaseNotes')!} isOpen={isShowReleaseNotesModalOpen} closeRequested={handleCloseAddDeviceModal}>
                    <HtmlEditor valueType='markdown' readOnly={true} defaultValue={getReleaseNotesContent(deviceDetails.data?.type)} />
                    <button className="btn btn-secondary" onClick={handleCloseAddDeviceModal}>
                        {t('Device.CloseReleaseNotes')}
                    </button>
                </ModalPopup>
            </React.Fragment>
        );
    });
};
