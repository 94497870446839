export class KnownRoutes {
    public static All = '*';
    public static Default = '/';
    public static Search = '/search';

    public static UserProfile = '/userprofile';

    public static Company = '/userprofile/company';
    public static CompanyMembers = '/userprofile/companymembers';

    // AOC
    public static AocDevice = '/aoc/device/:deviceid';
    public static AocDeviceSettings = '/aoc/device/:deviceid/settings';
    public static AocDeviceNotes = '/aoc/device/:deviceid/notes';

    // Dexit
    public static DexitDevice = '/dexit/device/:deviceid';
    public static DexitDeviceComponents = '/dexit/device/:deviceid/components';
    public static DexitNotificationSettings = '/dexit/device/:deviceid/notifications';
    public static DexitDeviceSettings = '/dexit/device/:deviceid/settings';
    public static DexitDeviceNotes = '/dexit/device/:deviceid/notes';
    public static DexitWLXManager = '/dexit/device/:deviceid/wlxmanager';
    public static DexitOperatorAdministratorPairings = '/dexit/device/:deviceid/assignments';

    // Hyen
    public static HyenDevice = '/hyen/device/:deviceid';
    public static HyenDeviceSettings = '/hyen/device/:deviceid/settings';
    public static HyenDeviceNotes = '/hyen/device/:deviceid/notes';
    public static HyenDeviceRemoteConnect = '/hyen/device/:deviceid/remoteconnect';
    public static HyenDeviceLogs = '/hyen/device/:deviceid/logs';
    public static HyenDeviceAppPairings = '/hyen/device/:deviceid/pairings';
    public static HyenNotificationSettings = '/hyen/device/:deviceid/notifications';
    public static HyenOperatorAdministratorPairings = '/hyen/device/:deviceid/assignments';

    // Comfion
    public static ComfionDevice = '/comfion/device/:deviceid';
    public static ComfionDeviceRemoteConnect = '/comfion/device/:deviceid/remoteconnect';
    public static ComfionNotificationSettings = '/comfion/device/:deviceid/notifications';
    public static ComfionDeviceSettings = '/comfion/device/:deviceid/settings';
    public static ComfionDeviceNotes = '/comfion/device/:deviceid/notes';

    // Firmware
    public static FirmwareOverview = '/firmware/overview';
    public static FirmwareApproval = '/firmware/:firmwareid/approval/';
    public static FirmwareDetails = '/firmware/:firmwareid/details/';

    public static Imprint = '/imprint';
    public static Cookies = '/cookies';
    public static Policy = '/policy';
    public static Login = '/login';
    public static Logout = '/logout';
    public static Register = '/register';

    public static Unauthorized = '/unauthorized';
    public static AccessDenied = '/accessdenied';
}
