import React from 'react';
import { ITabConfig } from '../../common/components/tab/tabs';
import { KnownRoutes } from '../../common/routing/knownroutes';
import { DeviceComponentOverview } from '../deviceComponent/deviceComponentOverview';
import { DeviceSettings } from '../deviceSettings/deviceSettings';
import { Log } from '../logs/logs';
import { RemoteAccess } from '../remoteaccess/remoteaccess';

import { Users } from '../pairings/pairings';
import { WLXManager } from '../wlxManager/wlxManager';
import { DexitNotificationSettings } from '../systemServices/dexitNotificationSettings';
import { HyenNotificationSettings } from '../systemServices/hyenNotificationSettings';
import { DeviceType } from '../stores/deviceStore';
import { ComfionNotificationSettings } from '../systemServices/comfionNotificationSettings';
import { OperatorDeviceAssignmentOverview } from '../operatorDeviceAssignmentsOverview/operatorDeviceAssignmentsOverview';
import { Notes } from '../notes/notes';

export const TabContent = (props: { tabConfig?: ITabConfig }) => {
    const deviceNotesRoutes = [KnownRoutes.AocDeviceNotes, KnownRoutes.DexitDeviceNotes, KnownRoutes.ComfionDeviceNotes, KnownRoutes.HyenDeviceNotes];

    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.DexitDeviceSettings || kr === KnownRoutes.HyenDeviceSettings || kr === KnownRoutes.ComfionDeviceSettings)) {
        return <DeviceSettings />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.DexitNotificationSettings)) {
        return <DexitNotificationSettings />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.HyenNotificationSettings)) {
        return <HyenNotificationSettings />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.ComfionNotificationSettings)) {
        return <ComfionNotificationSettings />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.DexitDeviceComponents)) {
        return <DeviceComponentOverview />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.DexitWLXManager)) {
        return <WLXManager />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.DexitOperatorAdministratorPairings)) {
        return <OperatorDeviceAssignmentOverview />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.HyenOperatorAdministratorPairings)) {
        return <OperatorDeviceAssignmentOverview />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.HyenDeviceLogs)) {
        return <Log />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.HyenDeviceAppPairings)) {
        return <Users />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.ComfionDeviceRemoteConnect)) {
        return <RemoteAccess deviceType={DeviceType.Comfion} />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => kr === KnownRoutes.HyenDeviceRemoteConnect)) {
        return <RemoteAccess deviceType={DeviceType.Hyen} />;
    }
    if (props.tabConfig?.path.some((kr: KnownRoutes) => deviceNotesRoutes.includes(kr.toString()))) {
        return <Notes></Notes>;
    }

    return null;
};
